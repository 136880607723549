import get from "lodash/get";
import concat from "lodash/concat";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import uniqBy from "lodash/uniqBy";

import { isLbsMeet, canadaMeet } from "./meetHelper";
import { EquipmentLevel, Gender, Meet } from "types";

export const getCustomQuestionTypeOptions = function () {
  return [{ label: "Text", value: "TEXT" }];
};

export const getClockTimerLengthOptions = function (platform: any) {
  const options = [
    { label: "1 min", value: 60000 },
    { label: "1.5 min", value: 90000 },
    { label: "2 min", value: 120000 },
    { label: "3 min", value: 180000 },
    { label: "4 min", value: 240000 },
    { label: "5 min", value: 300000 },
    { label: "6 min", value: 360000 },
    { label: "7 min", value: 420000 },
    { label: "8 min", value: 480000 },
    { label: "9 min", value: 540000 },
    { label: "10 min", value: 600000 },
    { label: "15 min", value: 900000 },
    { label: "20 min", value: 1200000 },
    { label: "25 min", value: 1500000 },
    { label: "30 min", value: 1800000 },
    { label: "45 min", value: 2700000 },
    { label: "1 hour", value: 3600000 },
    { label: "1 hour 15 mins", value: 4500000 },
    { label: "1 hour 30 mins", value: 5400000 },
    { label: "1 hour 45 mins", value: 6300000 },
    { label: "2 hours", value: 7200000 },
  ];

  if (
    platform.clockTimerLength &&
    !options.find((o) => platform.clockTimerLength === o.value)
  ) {
    options.unshift({
      label: `${platform.clockTimerLength / 60000} mins`,
      value: platform.clockTimerLength,
    });
  }

  return options;
};

export const getBarAndCollarsWeightOptions = function (
  lifter: any,
  meet: Meet
) {
  if (isLbsMeet(meet)) {
    return [
      { label: "15lbs", value: "15" },
      { label: "20lbs", value: "20" },
      { label: "25lbs", value: "25" },
      { label: "30lbs", value: "30" },
      { label: "35lbs", value: "35" },
      { label: "40lbs", value: "40" },
      { label: "45lbs", value: "45" },
      { label: "50lbs", value: "50" },
      { label: "55lbs", value: "55" },
      { label: "60lbs", value: "60" },
      { label: "65lbs", value: "65" },
      { label: "70lbs", value: "70" },
      { label: "75lbs", value: "75" },
    ];
  } else {
    return [
      { label: "5kg", value: "5" },
      { label: "10kg", value: "10" },
      { label: "15kg", value: "15" },
      { label: "20kg", value: "20" },
      { label: "25kg", value: "25" },
      { label: "30kg", value: "30" },
      { label: "35kg", value: "35" },
    ];
  }
};

export const getGenderOptions = (
  lifter: any,
  meet: Meet
): { value: Gender; label: string }[] => {
  if (meet.federation === "USAPL") {
    return [
      { value: "MALE", label: "Male" },
      { value: "FEMALE", label: "Female" },
      { value: "MX", label: "Mx" },
    ];
  } else if (meet.federation === "USSF") {
    return [
      { value: "MALE", label: "Open" },
      { value: "FEMALE", label: "Female" },
    ];
  }
  return [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
  ];
};

export const getDivisionCodeOptions = function (lifter: any, meet: Meet) {
  let options = [
    { value: "AUTO", label: "Auto Assign" },
    { value: "HIDE", label: "Hide On Export" },
    { value: "G", label: "G" },
  ];

  if (meet.federation === "IPF") {
    options = concat(options, [
      { value: "SJ", label: "SJ" },
      { value: "J", label: "J" },
      { value: "O", label: "O" },
      { value: "M1", label: "M1" },
      { value: "M2", label: "M2" },
      { value: "M3", label: "M3" },
      { value: "M4", label: "M4" },
    ]);
  } else if (meet.federation === "POWERLIFTING_AMERICA") {
    options = concat(options, [
      { value: "Y", label: "Y" },
      { value: "PS", label: "PS" },
      { value: "SJ", label: "SJ" },
      { value: "J", label: "J" },
      { value: "O", label: "O" },
      { value: "M1", label: "M1" },
      { value: "M2", label: "M2" },
      { value: "M3", label: "M3" },
      { value: "M4", label: "M4" },
      { value: "SO", label: "SO" },
    ]);
  } else if (
    meet.federation === "USPA" ||
    meet.federation === "OTHER" ||
    meet.federation === "MMP" ||
    meet.federation === "IPA" ||
    meet.federation === "100RAW"
  ) {
    options = concat(options, [
      { value: "Y", label: "Y" },
      { value: "Y1", label: "Y1" },
      { value: "Y2", label: "Y2" },
      { value: "Y3", label: "Y3" },
      { value: "T1", label: "T1" },
      { value: "T2", label: "T2" },
      { value: "T3", label: "T3" },
      { value: "T4", label: "T4" },
      { value: "Jr", label: "Jr" },
      { value: "J1", label: "J1" },
      { value: "J2", label: "J2" },
      { value: "J3", label: "J3" },
      { value: "J4", label: "J4" },
      { value: "O", label: "O" },
      { value: "SM", label: "SM" },
      { value: "M1", label: "M1" },
      { value: "M2", label: "M2" },
      { value: "M3", label: "M3" },
      { value: "M4", label: "M4" },
      { value: "M5", label: "M5" },
      { value: "M6", label: "M6" },
      { value: "M7", label: "M7" },
      { value: "M8", label: "M8" },
      { value: "M9", label: "M9" },
      { value: "PFM", label: "PFM" },
      { value: "H", label: "H" },
      { value: "SO", label: "SO" },
    ]);
  } else if (meet.federation === "RPS") {
    options = concat(options, [
      { value: "AM-T1", label: "AM-T1" },
      { value: "AM-T2", label: "AM-T2" },
      { value: "AM-T3", label: "AM-T3" },
      { value: "AM-JR", label: "AM-JR" },
      { value: "AM-O", label: "AM-O" },
      { value: "AM-SM", label: "AM-SM" },
      { value: "AM-M1", label: "AM-M1" },
      { value: "AM-M2", label: "AM-M2" },
      { value: "AM-M3", label: "AM-M3" },
      { value: "AM-M4", label: "AM-M4" },
      { value: "AM-M5", label: "AM-M5" },
      { value: "AM-M6", label: "AM-M6" },
      { value: "AM-M7", label: "AM-M7" },
      { value: "AM-M8", label: "AM-M8" },
      { value: "AM-M9", label: "AM-M9" },

      { value: "PRO-T1", label: "PRO-T1" },
      { value: "PRO-T2", label: "PRO-T2" },
      { value: "PRO-T3", label: "PRO-T3" },
      { value: "PRO-JR", label: "PRO-JR" },
      { value: "PRO-O", label: "PRO-O" },
      { value: "PRO-SM", label: "PRO-SM" },
      { value: "PRO-M1", label: "PRO-M1" },
      { value: "PRO-M2", label: "PRO-M2" },
      { value: "PRO-M3", label: "PRO-M3" },
      { value: "PRO-M4", label: "PRO-M4" },
      { value: "PRO-M5", label: "PRO-M5" },
      { value: "PRO-M6", label: "PRO-M6" },
      { value: "PRO-M7", label: "PRO-M7" },
      { value: "PRO-M8", label: "PRO-M8" },
      { value: "PRO-M9", label: "PRO-M9" },

      { value: "E-T1", label: "E-T1" },
      { value: "E-T2", label: "E-T2" },
      { value: "E-T3", label: "E-T3" },
      { value: "E-JR", label: "E-JR" },
      { value: "E-O", label: "E-O" },
      { value: "E-SM", label: "E-SM" },
      { value: "E-M1", label: "E-M1" },
      { value: "E-M2", label: "E-M2" },
      { value: "E-M3", label: "E-M3" },
      { value: "E-M4", label: "E-M4" },
      { value: "E-M5", label: "E-M5" },
      { value: "E-M6", label: "E-M6" },
      { value: "E-M7", label: "E-M7" },
      { value: "E-M8", label: "E-M8" },
      { value: "E-M9", label: "E-M9" },

      { value: "PF-AM", label: "PF-AM" },
      { value: "PF-PRO", label: "PF-PRO" },
      { value: "AF-AM", label: "AF-AM" },
      { value: "AF-PRO", label: "AF-PRO" },

      { value: "PF-AM-M", label: "PF-AM-M" },
      { value: "PF-PRO-M", label: "PF-PRO-M" },
      { value: "AF-AM-M", label: "AF-AM-M" },
      { value: "AF-PRO-M", label: "AF-PRO-M" },
    ]);
  } else {
    options = concat(options, [
      { value: "Y1", label: "Y1" },
      { value: "Y2", label: "Y2" },
      { value: "Y3", label: "Y3" },
      { value: "Y", label: "Y" },
      { value: "T1", label: "T1" },
      { value: "T2", label: "T2" },
      { value: "T3", label: "T3" },
      { value: "T", label: "T" },
      { value: "Jr", label: "Jr" },
      { value: "TJ", label: "TJ" },
      { value: "O", label: "O" },
      { value: "M1a", label: "M1a" },
      { value: "M1b", label: "M1b" },
      { value: "M1", label: "M1" },
      { value: "M2a", label: "M2a" },
      { value: "M2b", label: "M2b" },
      { value: "M2", label: "M2" },
      { value: "M3a", label: "M3a" },
      { value: "M3b", label: "M3b" },
      { value: "M3", label: "M3" },
      { value: "M4a", label: "M4a" },
      { value: "M4b", label: "M4b" },
      { value: "M4", label: "M4" },
      { value: "M5a", label: "M5a" },
      { value: "M5b", label: "M5b" },
      { value: "M5", label: "M5" },
      { value: "M6a", label: "M6a" },
      { value: "M6b", label: "M6b" },
      { value: "M6", label: "M6" },
      { value: "M", label: "M" },
      { value: "AA", label: "AA" },
      { value: "PB", label: "PB" },
      { value: "JV", label: "JV" },
      { value: "V", label: "V" },
      { value: "HS", label: "HS" },
      { value: "C", label: "C" },
      { value: "MO", label: "MO" },
      { value: "PF", label: "PF" },
      { value: "PFM", label: "PFM" },
      { value: "SO", label: "SO" },
      { value: "SJ", label: "SJ" },
      { value: "J", label: "J" },
    ]);
  }

  return options;
};

export const getRawOrEquippedOptions = (
  lifter: any,
  meet: Meet
): { value: EquipmentLevel; label: string }[] => {
  const options = {
    USPA: [
      { value: "RAW" as const, label: "Raw" },
      { value: "CLASSIC_RAW" as const, label: "Classic Raw" },
      { value: "SINGLE_PLY" as const, label: "Single Ply" },
      { value: "MULTI_PLY" as const, label: "Multi Ply" },
    ],
    OTHER: [
      { value: "RAW" as const, label: "Raw" },
      { value: "CLASSIC_RAW" as const, label: "Classic Raw" },
      { value: "RAW_WITH_WRAPS" as const, label: "Raw with Wraps" },
      { value: "EQUIPPED" as const, label: "Equipped" },
      { value: "SINGLE_PLY" as const, label: "Single Ply" },
      { value: "MULTI_PLY" as const, label: "Multi Ply" },
      { value: "UNLIMITED" as const, label: "Unlimited" },
    ],
    USSF: [{ value: "RAW" as const, label: "Raw" }],
    "100RAW": [{ value: "RAW" as const, label: "Raw" }],
    APA: [
      { value: "RAW" as const, label: "Raw" },
      { value: "EQUIPPED" as const, label: "Equipped" },
      { value: "UNLIMITED" as const, label: "Unlimited" },
    ],
    USAPL: [
      { value: "RAW" as const, label: "Raw" },
      { value: "RAW_WITH_WRAPS" as const, label: "Raw with Wraps" },
      { value: "EQUIPPED" as const, label: "Equipped" },
    ],
    RPS: [
      { value: "RAW_CLASSIC" as const, label: "Raw Classic" },
      { value: "RAW_MODERN" as const, label: "Raw Modern" },
      { value: "MULTIPLY" as const, label: "Multiply" },
      { value: "SINGLE_PLY" as const, label: "Single Ply" },
    ],
    MMP: [
      { value: "RAW_AMATEUR" as const, label: "Raw Amateur" },
      { value: "RAW_PRO" as const, label: "Raw Pro" },
      { value: "EQUIPPED_AMATEUR" as const, label: "Amateur Equipped" },
      { value: "EQUIPPED_PRO" as const, label: "Pro Equipped" },
    ],
    IPA: [
      { value: "RAW_AMATEUR" as const, label: "Raw Amateur" },
      { value: "RAW_PRO" as const, label: "Raw Pro" },
      { value: "EQUIPPED_AMATEUR" as const, label: "Amateur Equipped" },
      { value: "EQUIPPED_PRO" as const, label: "Pro Equipped" },
    ],
    WRPF: [
      { value: "RAW" as const, label: "Raw" },
      { value: "RAW_WITH_WRAPS" as const, label: "Raw with Wraps" },
      { value: "SINGLE_PLY" as const, label: "Single Ply" },
      { value: "MULTIPLY" as const, label: "Multiply" },
      { value: "UNLIMITED" as const, label: "Unlimited" },
    ],
  };

  const defaultOptions = [
    { value: "RAW" as const, label: "Raw" },
    { value: "EQUIPPED" as const, label: "Equipped" },
  ];

  if (!meet.federation) {
    return defaultOptions;
  }

  return get(options, meet.federation, defaultOptions);
};

export const getPlatformOptions = function (lifter: any, meet: Meet) {
  return map(sortBy(meet.platforms, "name"), (platform) => {
    return { value: platform._id, label: platform.name };
  });
};

export const getSessionOptions = function () {
  return [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ];
};

export const getFlightOptions = function () {
  return [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
    { value: "E", label: "E" },
    { value: "F", label: "F" },
    { value: "G", label: "G" },
    { value: "H", label: "H" },
    { value: "I", label: "I" },
    { value: "J", label: "J" },
    { value: "K", label: "K" },
    { value: "L", label: "L" },
    { value: "M", label: "M" },
    { value: "N", label: "N" },
    { value: "O", label: "O" },
    { value: "P", label: "P" },
    { value: "Q", label: "Q" },
    { value: "R", label: "R" },
    { value: "S", label: "S" },
    { value: "T", label: "T" },
    { value: "U", label: "U" },
    { value: "V", label: "V" },
    { value: "W", label: "W" },
    { value: "X", label: "X" },
    { value: "Y", label: "Y" },
    { value: "Z", label: "Z" },
  ];
};

export const getAwardDivisionOptions = function (
  lifter: any,
  meet: Meet,
  divisionIndex: number | undefined
) {
  const filteredDivisions = filter(meet.divisions, (division) => {
    return (
      !lifter._id ||
      divisionIndex === undefined ||
      (lifter.divisions[divisionIndex].rawOrEquipped ===
        division.rawOrEquipped &&
        lifter.gender === division.gender)
    );
  });
  const sortedDivisions = sortBy(filteredDivisions, "name");

  return map(sortedDivisions, (division) => {
    return { value: division._id, label: division.name };
  });
};

export const getWeightClassOptions = function (
  lifter: any,
  meet: Meet,
  divisionIndex: number | undefined
) {
  if (divisionIndex === undefined) {
    return [];
  }
  const divisionId = get(lifter, ["divisions", divisionIndex, "divisionId"]);
  const division = get(meet, ["divisions", divisionId], undefined);

  if (!division) {
    return [];
  }

  const sortedWeightClasses = sortBy(division.weightClasses, "maxWeight");

  return map(sortedWeightClasses, (weightClass) => {
    return { value: weightClass._id, label: weightClass.name };
  });
};

export const getScoreByOptions = function () {
  return [
    { value: "TOTAL", label: "Total" },
    { value: "BODY_WEIGHT_POINTS", label: "Wilks Points" },
    { value: "AGE_POINTS", label: "Age (Foster/McCulloch) Points" },
    { value: "BODY_WEIGHT_AND_AGE_POINTS", label: "Wilks and Age Points" },
    { value: "IPF_POINTS", label: "IPF Points" },
    { value: "IPF_AND_AGE_POINTS", label: "IPF and Age Points" },
    { value: "DOTS_POINTS", label: "DOTS Points" },
    { value: "DOTS_AND_AGE_POINTS", label: "DOTS and Age Points" },
    { value: "SCHWARTZ_MALONE", label: "Schwartz/Malone" },
    {
      value: "SCHWARTZ_MALONE_AND_AGE_POINTS",
      label: "Schwartz/Malone and Age Points",
    },
    { value: "GLOSSBRENNER_POINTS", label: "Glossbrenner Points" },
    {
      value: "GLOSSBRENNER_AND_AGE_POINTS",
      label: "Glossbrenner and Age Points",
    },
    { value: "PARA_DOTS_POINTS", label: "para DOTS Points" },
    { value: "AH_POINTS", label: "AH" },
    { value: "K_POINTS", label: "K-Points" },
    { value: "PERCENT_OF_RECORD", label: "Percent of record" },
  ];
};

// This if or if a drug test will be / has been performed
export const getDrugTestOptions = function () {
  return [
    { value: "Y", label: "Y" },
    { value: "N", label: "N" },
  ];
};

// This is for the category they enter
export const getTestedOptions = function () {
  return [
    { value: "UNTESTED", label: "Untested" },
    { value: "TESTED", label: "Tested" },
  ];
};

export const getRecordLocationOptions = function () {
  return [
    { value: "WORLD", label: "WORLD" },
    { value: "NATIONAL", label: "NATIONAL" },
  ];
};

export const getRecordCompetitionTypeOptions = function () {
  return [
    { value: "FULL-POWER", label: "FULL-POWER" },
    { value: "SINGLE-LIFT", label: "SINGLE-LIFT" },
    { value: "PUSH-PULL", label: "PUSH-PULL" },
  ];
};

export const getRecordLiftOptions = function () {
  return [
    { value: "SQUAT", label: "SQUAT" },
    { value: "BENCH-PRESS", label: "BENCH-PRESS" },
    { value: "DEADLIFT", label: "DEADLIFT" },
    { value: "TOTAL", label: "TOTAL" },
  ];
};

export const getStateOptions = function (lifter: any, meet: Meet) {
  if (canadaMeet(meet)) {
    return [
      { value: "N/A", label: "N/A" },
      { value: "AB", label: "AB" },
      { value: "BC", label: "BC" },
      { value: "MB", label: "MB" },
      { value: "NB", label: "NB" },
      { value: "NL", label: "NL" },
      { value: "NT", label: "NT" },
      { value: "NS", label: "NS" },
      { value: "NU", label: "NU" },
      { value: "ON", label: "ON" },
      { value: "PE", label: "PE" },
      { value: "QC", label: "QC" },
      { value: "SK", label: "SK" },
      { value: "YT", label: "YT" },
    ];
  }

  return [
    { value: "N/A", label: "N/A" },
    { value: "AL", label: "AL" },
    { value: "AK", label: "AK" },
    { value: "AZ", label: "AZ" },
    { value: "AR", label: "AR" },
    { value: "CA", label: "CA" },
    { value: "CO", label: "CO" },
    { value: "CT", label: "CT" },
    { value: "DE", label: "DE" },
    { value: "DC", label: "DC" },
    { value: "FL", label: "FL" },
    { value: "GA", label: "GA" },
    { value: "HI", label: "HI" },
    { value: "ID", label: "ID" },
    { value: "IL", label: "IL" },
    { value: "IN", label: "IN" },
    { value: "IA", label: "IA" },
    { value: "KS", label: "KS" },
    { value: "KY", label: "KY" },
    { value: "LA", label: "LA" },
    { value: "ME", label: "ME" },
    { value: "MD", label: "MD" },
    { value: "MA", label: "MA" },
    { value: "MI", label: "MI" },
    { value: "MN", label: "MN" },
    { value: "MS", label: "MS" },
    { value: "MO", label: "MO" },
    { value: "MT", label: "MT" },
    { value: "NE", label: "NE" },
    { value: "NV", label: "NV" },
    { value: "NH", label: "NH" },
    { value: "NJ", label: "NJ" },
    { value: "NM", label: "NM" },
    { value: "NY", label: "NY" },
    { value: "NC", label: "NC" },
    { value: "ND", label: "ND" },
    { value: "OH", label: "OH" },
    { value: "OK", label: "OK" },
    { value: "OR", label: "OR" },
    { value: "PA", label: "PA" },
    { value: "RI", label: "RI" },
    { value: "SC", label: "SC" },
    { value: "SD", label: "SD" },
    { value: "TN", label: "TN" },
    { value: "TX", label: "TX" },
    { value: "UT", label: "UT" },
    { value: "VT", label: "VT" },
    { value: "VA", label: "VA" },
    { value: "WA", label: "WA" },
    { value: "WV", label: "WV" },
    { value: "WI", label: "WI" },
    { value: "WY", label: "WY" },
  ];
};

export const getTeamOptions = function (lifter: any, meet: Meet) {
  return filter(
    uniqBy(
      map(meet.lifters, (lifter) => {
        return { value: lifter.team, label: lifter.team };
      }),
      "value"
    ),
    "value"
  );
};

export const getUnitOptions = function () {
  return [
    { value: "KG", label: "KG" },
    { value: "LBS", label: "LBS" },
  ];
};

export const getFederationOptions = function () {
  return [
    { value: "100RAW", label: "100%RAW" },
    { value: "APA", label: "APA" },
    { value: "CPU", label: "CPU" },
    { value: "IPA", label: "IPA" },
    { value: "IPF", label: "IPF" },
    { value: "MMP", label: "MMP" },
    { value: "POWERLIFTING_AMERICA", label: "POWERLIFTING AMERICA" },
    { value: "RPS", label: "RPS" },
    { value: "SSF", label: "SSF" },
    { value: "USAPL", label: "USAPL" },
    { value: "USPA", label: "USPA" },
    { value: "USSF", label: "USSF" },
    { value: "WPP", label: "WPP" },
    { value: "WRPF", label: "WRPF" },
    { value: "OTHER", label: "OTHER" },
  ];
};

export const getDateFormatOptions = function () {
  return [
    { value: "MM/DD/YYYY", label: "MM/DD/YYYY" },
    { value: "DD/MM/YYYY", label: "DD/MM/YYYY" },
    { value: "YYYY/MM/DD", label: "YYYY/MM/DD" },
  ];
};

export const getCountryOptions = function () {
  const countries = {
    AD: "Andorra",
    AE: "United Arab Emirates",
    AF: "Afghanistan",
    AG: "Antigua and Barbuda",
    AI: "Anguilla",
    AL: "Albania",
    AM: "Armenia",
    AN: "Netherlands Antilles",
    AO: "Angola",
    AQ: "Antarctica",
    AR: "Argentina",
    AS: "American Samoa",
    AT: "Austria",
    AU: "Australia",
    AW: "Aruba",
    AX: "\u00c5land Islands",
    AZ: "Azerbaijan",
    BA: "Bosnia and Herzegovina",
    BB: "Barbados",
    BD: "Bangladesh",
    BE: "Belgium",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BH: "Bahrain",
    BI: "Burundi",
    BJ: "Benin",
    BL: "Saint Barthélemy",
    BM: "Bermuda",
    BN: "Brunei Darussalam",
    BO: "Bolivia, Plurinational State of",
    BQ: "Caribbean Netherlands",
    BR: "Brazil",
    BS: "Bahamas",
    BT: "Bhutan",
    BV: "Bouvet Island",
    BW: "Botswana",
    BY: "Belarus",
    BZ: "Belize",
    CA: "Canada",
    CC: "Cocos (Keeling) Islands",
    CD: "Congo, the Democratic Republic of the",
    CF: "Central African Republic",
    CG: "Congo",
    CH: "Switzerland",
    CI: "C\u00f4te d'Ivoire",
    CK: "Cook Islands",
    CL: "Chile",
    CM: "Cameroon",
    CN: "China",
    CO: "Colombia",
    CR: "Costa Rica",
    CU: "Cuba",
    CV: "Cape Verde",
    CW: "Cura\u00e7ao",
    CX: "Christmas Island",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DE: "Germany",
    DJ: "Djibouti",
    DK: "Denmark",
    DM: "Dominica",
    DO: "Dominican Republic",
    DZ: "Algeria",
    EC: "Ecuador",
    EE: "Estonia",
    EG: "Egypt",
    EH: "Western Sahara",
    ER: "Eritrea",
    ES: "Spain",
    ET: "Ethiopia",
    EU: "Europe",
    FI: "Finland",
    FJ: "Fiji",
    FK: "Falkland Islands (Malvinas)",
    FM: "Micronesia, Federated States of",
    FO: "Faroe Islands",
    FR: "France",
    GA: "Gabon",
    "GB-ENG": "England",
    "GB-NIR": "Northern Ireland",
    "GB-SCT": "Scotland",
    "GB-WLS": "Wales",
    GB: "United Kingdom",
    GD: "Grenada",
    GE: "Georgia",
    GF: "French Guiana",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibraltar",
    GL: "Greenland",
    GM: "Gambia",
    GN: "Guinea",
    GP: "Guadeloupe",
    GQ: "Equatorial Guinea",
    GR: "Greece",
    GS: "South Georgia and the South Sandwich Islands",
    GT: "Guatemala",
    GU: "Guam",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HK: "Hong Kong",
    HM: "Heard Island and McDonald Islands",
    HN: "Honduras",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    ID: "Indonesia",
    IE: "Ireland",
    IL: "Israel",
    IM: "Isle of Man",
    IN: "India",
    IO: "British Indian Ocean Territory",
    IQ: "Iraq",
    IR: "Iran, Islamic Republic of",
    IS: "Iceland",
    IT: "Italy",
    JE: "Jersey",
    JM: "Jamaica",
    JO: "Jordan",
    JP: "Japan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KH: "Cambodia",
    KI: "Kiribati",
    KM: "Comoros",
    KN: "Saint Kitts and Nevis",
    KP: "Korea, Democratic People's Republic of",
    KR: "Korea, Republic of",
    KW: "Kuwait",
    KY: "Cayman Islands",
    KZ: "Kazakhstan",
    LA: "Lao People's Democratic Republic",
    LB: "Lebanon",
    LC: "Saint Lucia",
    LI: "Liechtenstein",
    LK: "Sri Lanka",
    LR: "Liberia",
    LS: "Lesotho",
    LT: "Lithuania",
    LU: "Luxembourg",
    LV: "Latvia",
    LY: "Libya",
    MA: "Morocco",
    MC: "Monaco",
    MD: "Moldova, Republic of",
    ME: "Montenegro",
    MF: "Saint Martin",
    MG: "Madagascar",
    MH: "Marshall Islands",
    MK: "Macedonia, the former Yugoslav Republic of",
    ML: "Mali",
    MM: "Myanmar",
    MN: "Mongolia",
    MO: "Macao",
    MP: "Northern Mariana Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MS: "Montserrat",
    MT: "Malta",
    MU: "Mauritius",
    MV: "Maldives",
    MW: "Malawi",
    MX: "Mexico",
    MY: "Malaysia",
    MZ: "Mozambique",
    NA: "Namibia",
    NC: "New Caledonia",
    NE: "Niger",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NI: "Nicaragua",
    NL: "Netherlands",
    NO: "Norway",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    NZ: "New Zealand",
    OM: "Oman",
    PA: "Panama",
    PE: "Peru",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PH: "Philippines",
    PK: "Pakistan",
    PL: "Poland",
    PM: "Saint Pierre and Miquelon",
    PN: "Pitcairn",
    PR: "Puerto Rico",
    PS: "Palestine",
    PT: "Portugal",
    PW: "Palau",
    PY: "Paraguay",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Romania",
    RS: "Serbia",
    RU: "Russian Federation",
    RW: "Rwanda",
    SA: "Saudi Arabia",
    SB: "Solomon Islands",
    SC: "Seychelles",
    SD: "Sudan",
    SE: "Sweden",
    SG: "Singapore",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    SI: "Slovenia",
    SJ: "Svalbard and Jan Mayen Islands",
    SK: "Slovakia",
    SL: "Sierra Leone",
    SM: "San Marino",
    SN: "Senegal",
    SO: "Somalia",
    SR: "Suriname",
    SS: "South Sudan",
    ST: "Sao Tome and Principe",
    SV: "El Salvador",
    SX: "Sint Maarten (Dutch part)",
    SY: "Syrian Arab Republic",
    SZ: "Swaziland",
    TC: "Turks and Caicos Islands",
    TD: "Chad",
    TF: "French Southern Territories",
    TG: "Togo",
    TH: "Thailand",
    TJ: "Tajikistan",
    TK: "Tokelau",
    TL: "Timor-Leste",
    TM: "Turkmenistan",
    TN: "Tunisia",
    TO: "Tonga",
    TR: "Turkey",
    TT: "Trinidad and Tobago",
    TV: "Tuvalu",
    TW: "Taiwan",
    TZ: "Tanzania, United Republic of",
    UA: "Ukraine",
    UG: "Uganda",
    UM: "US Minor Outlying Islands",
    US: "United States",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VA: "Holy See (Vatican City State)",
    VC: "Saint Vincent and the Grenadines",
    VE: "Venezuela, Bolivarian Republic of",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    VN: "Viet Nam",
    VU: "Vanuatu",
    WF: "Wallis and Futuna Islands",
    XK: "Kosovo",
    WS: "Samoa",
    YE: "Yemen",
    YT: "Mayotte",
    ZA: "South Africa",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  };

  return sortBy(
    map(countries, (label, value) => ({ label, value })),
    "label"
  );
};
