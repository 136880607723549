export const PUT_MEET = "PUT_MEET";
export const REMOVE_MEET = "REMOVE_MEET";
export const PUT_RESTRICTED_MEET = "PUT_RESTRICTED_MEET";
export const PUT_ENTRY_CONFIG = "PUT_ENTRY_CONFIG";
export const PUT_RECORDS = "PUT_RECORDS";
export const PUT_PLATFORM = "PUT_PLATFORM";
export const REMOVE_PLATFORM = "REMOVE_PLATFORM";
export const SET_CURRENT_MEET_ID = "SET_CURRENT_MEET_ID";
export const PUT_LIFTER = "PUT_LIFTER";
export const PUT_RESTRICTED_LIFTER = "PUT_RESTRICTED_LIFTER";
export const REMOVE_LIFTER = "REMOVE_LIFTER";
export const PUT_ATTEMPT = "PUT_ATTEMPT";
export const START_ATTEMPT_TIMER = "START_ATTEMPT_TIMER";
export const PUT_DIVISION = "PUT_DIVISION";
export const REMOVE_DIVISION = "REMOVE_DIVISION";
export const PUT_WEIGHT_CLASS = "PUT_WEIGHT_CLASS";
export const PUT_REF = "PUT_REF";
export const REMOVE_WEIGHT_CLASS = "REMOVE_WEIGHT_CLASS";
export const MEET_IS_SYNCING = "MEET_IS_SYNCING";
export const MEET_IS_NOT_SYNCING = "MEET_IS_NOT_SYNCING";
export const MEET_IS_ACTIVELY_SYNCING = "MEET_IS_ACTIVELY_SYNCING";
export const MEET_IS_NOT_ACTIVELY_SYNCING = "MEET_IS_NOT_ACTIVELY_SYNCING";
export const MEET_IS_LOADING = "MEET_IS_LOADING";
export const MEET_IS_NOT_LOADING = "MEET_IS_NOT_LOADING";
export const MEET_IS_LOGGED_IN = "MEET_IS_LOGGED_IN";
export const MEET_IS_LOGGED_OUT = "MEET_IS_LOGGED_OUT";
export const MEET_IS_LOCAL = "MEET_IS_LOCAL";
export const MEET_IS_ONLINE = "MEET_IS_ONLINE";
export const MEET_NOT_FOUND = "MEET_NOT_FOUND";
export const SET_SCREEN_SIZE = "SET_SCREEN_SIZE";
