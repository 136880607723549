import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import get from "lodash/get";
import classNames from "classnames";
import { Helmet } from "react-helmet";

import {
  getIsLoading,
  getIsSyncing,
  getIsSyncActive,
  getIsLoggedIn,
  getIsLocal,
  getIsOnline,
  getCurrentMeet,
  getCurrentPlatform,
  getLifter,
} from "selectors";

import Navigation from "app/meets/Navigation";
import ConnectedIcon from "icons/ConnectedIcon";

class LifterTitle extends Component {
  render() {
    const lifterName = get(this.props.lifter, ["name"], "");
    const meetName = get(this.props.meet, "name");

    if (!meetName) {
      return null;
    }

    const pageTitle = `${lifterName} - ${meetName}`;
    return (
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {pageTitle}
      </div>
    );
  }
}

const mapStateToPropsLifterTitle = (state, props) => {
  return {
    meet: getCurrentMeet(state, props),
    lifter: getLifter(state, props),
  };
};

LifterTitle = withRouter(connect(mapStateToPropsLifterTitle, {})(LifterTitle));

class PlatformTitle extends Component {
  render() {
    const currentPlatformName = get(this.props.currentPlatform, ["name"], "");
    const meetName = get(this.props.meet, "name");
    const pageTitle = `Platform ${currentPlatformName} - ${meetName}`;

    if (!meetName) {
      return null;
    }

    return (
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {pageTitle}
      </div>
    );
  }
}

const mapStateToPropsPlatformTitle = (state, props) => {
  return {
    meet: getCurrentMeet(state, props),
    currentPlatform: getCurrentPlatform(state, props),
  };
};

PlatformTitle = withRouter(
  connect(mapStateToPropsPlatformTitle, {})(PlatformTitle)
);

class MeetTitle extends Component {
  render() {
    const meetName = get(this.props.meet, "name");

    if (!meetName) {
      return null;
    }

    return (
      <div>
        <Helmet>
          <title>{meetName}</title>
        </Helmet>
        {meetName}
      </div>
    );
  }
}

const mapStateToPropsMeetTitle = (state, props) => {
  return {
    meet: getCurrentMeet(state, props),
  };
};

MeetTitle = withRouter(connect(mapStateToPropsMeetTitle, {})(MeetTitle));

class Header extends Component {
  render() {
    const {
      match,
      meet,
      isSyncing,
      isSyncActive,
      isLoading,
      isLoggedIn,
      isLocal,
      isOnline,
      openPasswordModal,
    } = this.props;

    return (
      <div className="header">
        <Navigation
          meet={meet}
          match={match}
          isOnline={isOnline}
          isLocal={isLocal}
          isLoggedIn={isLoggedIn}
          openPasswordModal={openPasswordModal}
        />

        <div className="meet-name">
          <Switch>
            <Route
              path="/meets/:meetId/lifter/:lifterId"
              component={LifterTitle}
            />
            <Route
              path="/meets/:meetId/platforms/:platformId"
              component={PlatformTitle}
            />
            <Route path="/meets/:meetId" component={MeetTitle} />
          </Switch>
        </div>
        {!isLoading && isLocal && isOnline && (
          <div className="sync-status">
            <ConnectedIcon
              isSyncing={isSyncing}
              className={classNames({
                "sync-disabled": !isSyncing,
                "sync-active": isSyncActive,
              })}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    isLoading: getIsLoading(state, props),
    isSyncing: getIsSyncing(state, props),
    isSyncActive: getIsSyncActive(state, props),
    isLoggedIn: getIsLoggedIn(state, props),
    isLocal: getIsLocal(state, props),
    isOnline: getIsOnline(state, props),
    meet: getCurrentMeet(state, props),
  };
};

const actions = {};

export default withRouter(connect(mapStateToProps, actions)(Header));
