import React from "react";
import map from "lodash/map";
import get from "lodash/get";
import classNames from "classnames";

import { getLifterAwardsWeightClassDoc } from "util/lifterHelper";

const WeightClassCell = ({ data, column, meet, style }) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {map(lifter.divisions, (division, index) => {
          const weightClassDoc = getLifterAwardsWeightClassDoc(
            meet,
            lifter,
            division
          );
          const weightClassId = weightClassDoc._id;
          const isAWeightClassChange =
            division.declaredAwardsWeightClassId &&
            division.declaredAwardsWeightClassId !== weightClassId;
          return (
            <div
              key={index}
              className={classNames("inner-row", {
                "weight-class-change": isAWeightClassChange,
              })}
            >
              {get(weightClassDoc, "name")}
            </div>
          );
        })}
      </div>
    );
  }
};

export default WeightClassCell;
