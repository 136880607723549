import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

import ClockIcon from "icons/ClockIcon";

import { getRunningAttemptInputClocks } from "selectors";

const NextAttemptInputTimers = ({ runningAttemptInputClocks }) => {
  const [clocks, setClocks] = React.useState([]);

  React.useEffect(() => {
    const updateClocks = () => {
      const newClocks = runningAttemptInputClocks.map((a) => {
        return {
          name: a.lifter?.name,
          timeRemaining:
            60 - Math.round((Date.now() - a.attempt?.startTime) / 1000),
        };
      });
      setClocks(newClocks);
    };

    updateClocks();

    const interval = setInterval(updateClocks, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [runningAttemptInputClocks]);

  return (
    <>
      {clocks.map((c) => {
        const pastDue = c.timeRemaining <= 0;
        return (
          <div
            key={c.name + c.timeRemaining}
            className={classNames(
              pastDue && "past-due-attempt-input",
              "next-input-timer"
            )}
          >
            <ClockIcon />
            {c.name} : {pastDue ? 0 : c.timeRemaining}
          </div>
        );
      })}
    </>
  );
};

const mapStateToProps = (state, props) => {
  return {
    runningAttemptInputClocks: getRunningAttemptInputClocks(state, props),
  };
};

const actions = {};

export default withRouter(
  connect(mapStateToProps, actions)(NextAttemptInputTimers)
);
