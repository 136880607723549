import React from "react";
import map from "lodash/map";
import get from "lodash/get";

import PouchSelect from "components/PouchSelect";

const DivisionCell = ({ data, column, meet, style }) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {map(lifter.divisions, (division, index) => {
          return (
            <div key={index} className="inner-row">
              <PouchSelect
                key={index}
                index={index}
                style={{ width: column.width }}
                meet={meet}
                document={lifter}
                name={["divisions", index, column.key]}
                value={get(lifter, ["divisions", index, column.key])}
                options={column.options}
                clearable
              />
            </div>
          );
        })}
      </div>
    );
  }
};

export default DivisionCell;
