import round from "lodash/round";
import React from "react";
import { Meet } from "types";
import { getScore } from "util/lifterHelper";

const ScoreCell = ({
  data,
  meet,
  style,
}: {
  data: any;
  meet: Meet;
  style: React.CSSProperties;
}) => {
  const lifter = data;
  const division = lifter.division;
  const forecasted = lifter.forecasted;

  if (lifter.row === "header") {
    let label;
    if (!division) {
      label = "";
    } else if (division.scoreBy === "BODY_WEIGHT_POINTS") {
      label = "Wilks";
    } else if (division.scoreBy === "AGE_POINTS") {
      label = "Age Pts.";
    } else if (division.scoreBy === "BODY_WEIGHT_AND_AGE_POINTS") {
      label = "Wilks/Age Pts.";
    } else if (division.scoreBy === "IPF_POINTS") {
      label = "IPF Pts.";
    } else if (division.scoreBy === "IPF_AND_AGE_POINTS") {
      label = "IPF/Age Pts.";
    } else if (division.scoreBy === "DOTS_POINTS") {
      label = "DOTS Pts.";
    } else if (division.scoreBy === "DOTS_AND_AGE_POINTS") {
      label = "DOTS/Age Pts.";
    } else if (division.scoreBy === "SCHWARTZ_MALONE") {
      label = "S/M Pts";
    } else if (division.scoreBy === "SCHWARTZ_MALONE_AND_AGE_POINTS") {
      label = "S/M/Age Pts";
    } else if (division.scoreBy === "GLOSSBRENNER_POINTS") {
      label = "Glossbrenner";
    } else if (division.scoreBy === "GLOSSBRENNER_AND_AGE_POINTS") {
      label = "Gloss/Age Pts";
    } else if (division.scoreBy === "AH_POINTS") {
      label = "AH";
    } else if (division.scoreBy === "PARA_DOTS_POINTS") {
      label = "paraDOTS";
    } else if (division.scoreBy === "K_POINTS") {
      label = "K-points";
    } else if (division.scoreBy === "PERCENT_OF_RECORD") {
      label = "% Score";
    } else {
      label = "Total";
    }

    if (forecasted) {
      label = (
        <span>
          Forecasted
          <br />
          {label}
        </span>
      );
    }
    return (
      <div className="table-cell-inner" style={style}>
        {label}
      </div>
    );
  } else {
    const score = getScore(lifter, division, meet, forecasted);
    if (!score) {
      return null;
    }
    return (
      <div style={style} className="table-cell-inner">
        {round(score, 3)}
      </div>
    );
  }
};

export default ScoreCell;
