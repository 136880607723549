import React from "react";
import { Attempt } from "types";
import { useRecordAttempts } from "util/useRecordAttempts";

export const RecordsForAttempt = ({ attempt }: { attempt: Attempt }) => {
  const allRecords = useRecordAttempts();

  const recordsForAttempt = allRecords[attempt._id];

  if (recordsForAttempt) {
    return <> R</>;
  }

  return null;
};
