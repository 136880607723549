import React from "react";
import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import RecordsTable from "./RecordsTable";
import "./RecordsIndex.scss";
import { exportRecords } from "util/records";
import { getExpandedRecords } from "util/records";
import some from "lodash/some";
import Drawer from "components/drawer/Drawer";
import FiltersIcon from "icons/FiltersIcon";
import Select from "components/select/Select";
import {
  getDivisionCodeOptions,
  getGenderOptions,
  getRawOrEquippedOptions,
  getRecordCompetitionTypeOptions,
  getRecordLiftOptions,
  getRecordLocationOptions,
  getTestedOptions,
} from "util/options";
import isEmpty from "lodash/isEmpty";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import { useMeet } from "util/useMeet";
import { useMediaSize } from "util/useMediaSize";

const initialFilters: Record<
  | "gender"
  | "equipmentLevel"
  | "drugTested"
  | "divisionCode"
  | "weightClass"
  | "competitionType"
  | "lift"
  | "location",
  string | null
> = {
  gender: null,
  equipmentLevel: null,
  drugTested: null,
  divisionCode: null,
  weightClass: null,
  competitionType: null,
  lift: null,
  location: null,
};

const RecordsIndex = () => {
  const media = useMediaSize();
  const meet = useMeet();

  const expandedRecords = React.useMemo(() => getExpandedRecords(meet), [meet]);

  const [filters, setFilters] = React.useState(initialFilters);

  const clearFilters = () => {
    setFilters(initialFilters);
  };

  const onChangeSelectFilter = (
    name: keyof typeof initialFilters,
    value: string | number | undefined | null
  ) => {
    if (!value || typeof value !== "string") {
      setFilters({ ...filters, [name]: null });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const filteredExpandedRecords = expandedRecords.filter((expandedRecord) => {
    const genderMatch =
      !filters.gender || expandedRecord.gender === filters.gender;
    const equipmentLevelMatch =
      !filters.equipmentLevel ||
      expandedRecord.equipmentLevel === filters.equipmentLevel;
    const drugTestedMatch =
      !filters.drugTested || expandedRecord.drugTested === filters.drugTested;
    const divisionCodeMatch =
      !filters.divisionCode ||
      expandedRecord.divisionCode === filters.divisionCode;
    const weightClassMatch =
      !filters.weightClass ||
      expandedRecord.weightClass === filters.weightClass;
    const competitionTypeMatch =
      !filters.competitionType ||
      expandedRecord.competitionType === filters.competitionType;
    const liftMatch = !filters.lift || expandedRecord.lift === filters.lift;
    const locationMatch =
      !filters.location || expandedRecord.location === filters.location;
    return (
      genderMatch &&
      equipmentLevelMatch &&
      drugTestedMatch &&
      divisionCodeMatch &&
      weightClassMatch &&
      competitionTypeMatch &&
      liftMatch &&
      locationMatch
    );
  });

  const activeFilters = some(filters);

  return (
    <FixedHeightWrapper>
      <div className="divisions-index">
        <div className="util-row">
          <button
            onClick={() => {
              return exportRecords(meet);
            }}
            style={{ marginRight: 12 }}
          >
            Export Records
          </button>
          <Drawer trigger={<FiltersIcon active={activeFilters} />}>
            <div className="title">Records Filters</div>
            <button className="clear-button" onClick={clearFilters}>
              Clear All
            </button>
            <div className="row">
              Gender:
              <Select
                name="gender"
                value={filters.gender}
                options={getGenderOptions({}, meet)}
                onChange={(value) => onChangeSelectFilter("gender", value)}
              />
            </div>
            <div className="row">
              Equipment Level:
              <Select
                name="equipmentLevel"
                value={filters.equipmentLevel}
                options={getRawOrEquippedOptions({}, meet)}
                onChange={(value) =>
                  onChangeSelectFilter("equipmentLevel", value)
                }
              />
            </div>
            <div className="row">
              Drug Tested:
              <Select
                name="drugTested"
                value={filters.drugTested}
                options={getTestedOptions()}
                onChange={(value) => onChangeSelectFilter("drugTested", value)}
              />
            </div>
            <div className="row">
              Division Code:
              <Select
                name="divisionCode"
                value={filters.divisionCode}
                options={getDivisionCodeOptions({}, meet).filter(
                  (o) => !["AUTO", "HIDE", "G"].includes(o.value)
                )}
                onChange={(value) =>
                  onChangeSelectFilter("divisionCode", value)
                }
              />
            </div>
            <div className="row">
              Weight Class:
              <Select
                name="weightClass"
                value={filters.weightClass}
                options={uniqWith(
                  expandedRecords.map((e) => {
                    return { label: e.weightClass, value: e.weightClass };
                  }),
                  isEqual
                )}
                onChange={(value) => onChangeSelectFilter("weightClass", value)}
              />
            </div>
            <div className="row">
              Competition Type:
              <Select
                name="competitionType"
                value={filters.competitionType}
                options={getRecordCompetitionTypeOptions()}
                onChange={(value) =>
                  onChangeSelectFilter("competitionType", value)
                }
              />
            </div>
            <div className="row">
              Lift:
              <Select
                name="lift"
                value={filters.lift}
                options={getRecordLiftOptions()}
                onChange={(value) => onChangeSelectFilter("lift", value)}
              />
            </div>
            <div className="row">
              location:
              <Select
                name="location"
                value={filters.location}
                options={getRecordLocationOptions()}
                onChange={(value) => onChangeSelectFilter("location", value)}
              />
            </div>
          </Drawer>
          <div style={{ marginLeft: 20 }}>
            These are the original records you imported. Updated records from
            attempts in this meet are not shown here. Showing{" "}
            {filteredExpandedRecords.length} records.
          </div>
        </div>
        <div className="table-wrapper">
          {!expandedRecords || isEmpty(filteredExpandedRecords) ? (
            <div>
              {activeFilters ? (
                <div>No matching records</div>
              ) : (
                <div>
                  No records loaded yet. Load records from the import page.
                </div>
              )}
            </div>
          ) : (
            <RecordsTable
              meet={meet}
              expandedRecords={filteredExpandedRecords}
              media={media}
            />
          )}
        </div>
      </div>
    </FixedHeightWrapper>
  );
};

export default RecordsIndex;
