import React from "react";
import some from "lodash/some";
import each from "lodash/each";
import cloneDeep from "lodash/cloneDeep";
import size from "lodash/size";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import FileUpload from "components/fileUpload/FileUpload";
import { Meet } from "types";

const Import = ({
  meet,
  exportItems,
  label,
  media,
  itemsTable: ItemsTable,
  compareFunction,
  compareItems,
  addItem,
  parseItems,
  onFinishBulkCreate,
}: {
  meet: Meet;
  exportItems: any;
  label: any;
  media: any;
  itemsTable: any;
  compareFunction?: any;
  compareItems?: any;
  addItem: any;
  parseItems: any;
  onFinishBulkCreate: any;
}) => {
  const [items, setItems] = React.useState([]);

  const load = (csvString: any) => {
    console.log("csvString", csvString);
    const newItems = parseItems(csvString, meet);
    console.log("newItems", newItems);
    setItems(newItems);
  };

  const cleanItem = (item: any) => {
    const cleanedItem = cloneDeep(item);
    delete cleanedItem.match;
    if (item.divisions) {
      each(cleanedItem.divisions, (lifterDivision) => {
        delete lifterDivision.match;
      });
    }

    return cleanedItem;
  };

  const save = () => {
    const itemType = label.toLowerCase();

    if (compareItems && compareFunction) {
      const willCreateDuplicatesWithExistingData = some(items, (item) => {
        return some(compareItems, (compareItem) => {
          return compareFunction(compareItem, item);
        });
      });

      if (willCreateDuplicatesWithExistingData) {
        alert(
          `Can't import this data set because there are already ${itemType} with the same name. Continuing would cause duplicated ${itemType}.`
        );
        return;
      }

      const hasDuplicatesInData = some(items, (item) => {
        return (
          size(
            filter(items, (compareItem) => {
              return compareFunction(compareItem, item);
            })
          ) > 1
        );
      });

      if (hasDuplicatesInData) {
        alert(
          `Can't import this data set because it contains duplicate ${itemType}. Continuing would cause duplicated ${itemType}.`
        );
        return;
      }
    }

    each(items, (item) => {
      const cleanedItem = cleanItem(item);
      addItem(meet._id, cleanedItem);
    });
    const count = size(items);
    setItems([]);
    onFinishBulkCreate(count);
  };

  const importDisabled = isEmpty(items);

  return (
    <div className="import">
      <div className="util-row">
        <FileUpload
          accept="csv"
          buttonText={`Select ${label} file`}
          onChange={load}
        />
        <button disabled={importDisabled} onClick={save}>
          Confirm Import of {label}
        </button>
        <button onClick={() => exportItems(meet, [], "import")}>
          Download Template
        </button>
      </div>
      <div className="table-wrapper">
        <ItemsTable meet={meet} items={items} media={media} />
      </div>
    </div>
  );
};

export default Import;
