import React from "react";

import { Link } from "react-router-dom";

import { updateAttributeOnDocument } from "util/pouchActions";
import { getCustomQuestionTypeOptions } from "util/options";
import { generateId } from "util/docHelper";
import PouchInput from "components/PouchInput";
import PouchTextArea from "components/PouchTextArea";
import PouchCheckbox from "components/PouchCheckbox";
import PouchSelect from "components/PouchSelect";
import DeleteIcon from "icons/DeleteIcon";
import get from "lodash/get";
import { Meet } from "types";
import reject from "lodash/reject";
import map from "lodash/map";

type Item = { name: string; cost: number; id: string };
type Question = {
  text: string;
  required: boolean;
  type: "TEXT";
  id: string;
};

const Registration = ({
  meet,
  isOnline,
}: {
  meet: Meet;
  isOnline: boolean;
}) => {
  const addItem = () => {
    let items = meet.entryConfig.items || [];
    items = [...items, { name: "", cost: 0, id: generateId("") }];
    updateAttributeOnDocument(meet._id, meet.entryConfig._id, "items", items);
  };

  const removeItem = (item: Item) => {
    let items = meet.entryConfig.items || [];
    items = reject(items, (i) => i.id === item.id);
    updateAttributeOnDocument(meet._id, meet.entryConfig._id, "items", items);
  };

  const addQuestion = () => {
    let customQuestions = meet.entryConfig.customQuestions || [];
    customQuestions = [
      ...customQuestions,
      { text: "", required: false, type: "TEXT", id: generateId("") },
    ];
    updateAttributeOnDocument(
      meet._id,
      meet.entryConfig._id,
      "customQuestions",
      customQuestions
    );
  };

  const removeQuestion = (question: Question) => {
    let customQuestions = meet.entryConfig.customQuestions || [];
    customQuestions = reject(customQuestions, (q) => q.id === question.id);
    updateAttributeOnDocument(
      meet._id,
      meet.entryConfig._id,
      "customQuestions",
      customQuestions
    );
  };

  const stripeErrors = () => {
    const errorMessages = [];
    const stripePublicKey = get(meet.entryConfig, "stripePublicKey");
    const stripeSecretKey = get(meet.restricted, "stripeSecretKey");
    if (
      (stripePublicKey && stripePublicKey.includes("...")) ||
      (stripeSecretKey && stripeSecretKey.includes("..."))
    ) {
      errorMessages.push(
        'Stripe keys do not contain "...". This is a preview of the full key. You may need to reset (roll) the key to view the entire key.'
      );
    }

    if (
      stripePublicKey &&
      !stripePublicKey.includes("pk_live_") &&
      !stripePublicKey.includes("pk_test_")
    ) {
      errorMessages.push(
        'Stripe Publishable Key must start with "pk_live_" or "pk_test_".'
      );
    }

    if (
      stripeSecretKey &&
      !stripeSecretKey.includes("sk_live_") &&
      !stripeSecretKey.includes("sk_test_") &&
      !stripeSecretKey.includes("rk_live_") &&
      !stripeSecretKey.includes("rk_test_")
    ) {
      errorMessages.push(
        'Stripe Secret Key must start with "sk_live_", "rk_live_", "sk_test_", or "rk_test_".'
      );
    }

    if (stripeSecretKey && stripeSecretKey.startsWith("sk_")) {
      errorMessages.push(
        'It is recommended to use a Restricted Key for your Stripe Secret Key. Restricted keys start with  "rk_live_" or "rk_test_".  You can generate a restricted key with the correct permissions by installing the LiftingCast Stripe App here https://marketplace.stripe.com/apps/liftingcast-payment-link'
      );
    }

    if (
      (stripePublicKey &&
        stripePublicKey.includes("test") &&
        stripeSecretKey &&
        stripeSecretKey.includes("live")) ||
      (stripePublicKey &&
        stripePublicKey.includes("live") &&
        stripeSecretKey &&
        stripeSecretKey.includes("test"))
    ) {
      errorMessages.push(
        'Stripe keys must be both of type "live" or of type "test".'
      );
    }

    return errorMessages.join(" ");
  };

  const stripeMode = () => {
    const stripePublicKey = get(meet.entryConfig, "stripePublicKey");
    const stripeSecretKey = get(meet.restricted, "stripeSecretKey");
    if (
      stripePublicKey &&
      stripePublicKey.includes("test") &&
      stripeSecretKey &&
      stripeSecretKey.includes("test")
    ) {
      return "In TEST mode. Use Credit Card number 4242 4242 4242 4242 exp 03/33, code 333 to test.";
    }
  };

  return (
    <div className="entry-config">
      <div className="registration-title">Online Registration</div>
      {!isOnline && (
        <div>
          Meet must be uploaded online before registration will be available.
        </div>
      )}
      <div className="entry-row">
        <label>Registration is open:</label>
        <div className="input-wrapper">
          <PouchCheckbox
            meetId={meet._id}
            documentId={meet.entryConfig._id}
            name="isOpen"
            value={get(meet.entryConfig, "isOpen")}
          />
        </div>
      </div>
      <div className="entry-row">
        <label>Registration Link:</label>
      </div>
      <div className="entry-row">
        <div className="input-wrapper">
          <Link
            to={`/meets/${meet._id}/registration`}
          >{`${window.location.protocol}//${window.location.hostname}/meets/${meet._id}/registration`}</Link>
        </div>
      </div>
      {(!meet.federation || meet.federation === "USAPL") && (
        <div className="entry-row">
          <label>
            Require Valid
            <br />
            USAPL Member #:
          </label>
          <div className="input-wrapper">
            <PouchCheckbox
              meetId={meet._id}
              documentId={meet.entryConfig._id}
              name="requireValidMemberNumbers"
              value={get(meet.entryConfig, "requireValidMemberNumbers")}
            />
          </div>
        </div>
      )}
      <div className="entry-row">
        <label>Max number of entries:</label>
        <div className="input-wrapper">
          <PouchInput
            type="number"
            meetId={meet._id}
            documentId={meet.entryConfig._id}
            name="maxEntries"
            value={get(meet.entryConfig, "maxEntries")}
          />
        </div>
      </div>
      <div className="entry-row">
        <label>Full Powerlifting entry cost:</label>
        <div className="input-wrapper">
          <PouchInput
            type="number"
            meetId={meet._id}
            documentId={meet.entryConfig._id}
            name="fullPowerliftingCost"
            value={get(meet.entryConfig, "fullPowerliftingCost")}
          />
        </div>
      </div>
      <div className="entry-row">
        <label>One/Two lift entry cost:</label>
        <div className="input-wrapper">
          <PouchInput
            type="number"
            meetId={meet._id}
            documentId={meet.entryConfig._id}
            name="oneTwoLiftCost"
            value={get(meet.entryConfig, "oneTwoLiftCost")}
          />
        </div>
      </div>
      <div className="entry-row">
        <label>
          Additional division cost
          <br />
          (leave blank if not allowed):
        </label>
        <div className="input-wrapper">
          <PouchInput
            type="number"
            meetId={meet._id}
            documentId={meet.entryConfig._id}
            name="additionalDivisionCost"
            value={get(meet.entryConfig, "additionalDivisionCost")}
          />
        </div>
      </div>
      <div className="entry-row">
        <label>Description:</label>
        <div className="input-wrapper">
          <PouchTextArea
            meetId={meet._id}
            documentId={meet.entryConfig._id}
            name="description"
            value={get(meet.entryConfig, "description")}
          />
        </div>
      </div>
      <div className="entry-row">
        <label>Disclaimer:</label>
        <div className="input-wrapper">
          <PouchTextArea
            meetId={meet._id}
            documentId={meet.entryConfig._id}
            name="disclaimer"
            value={get(meet.entryConfig, "disclaimer")}
          />
        </div>
      </div>
      <div className="entry-row">
        <label>Website URL:</label>
        <div className="input-wrapper">
          <PouchInput
            type="text"
            placeholder="http://example.com"
            meetId={meet._id}
            documentId={meet.entryConfig._id}
            name="website"
            value={get(meet.entryConfig, "website")}
          />
        </div>
      </div>

      <hr />
      <div>
        Install the LiftingCast Stripe app to your Stripe account to generate
        the correct authorization keys.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://marketplace.stripe.com/apps/liftingcast-payment-link"
        >
          https://marketplace.stripe.com/apps/liftingcast-payment-link
        </a>
      </div>
      <div className="entry-row">
        <label>Stripe Publishable Key:</label>
        <div className="input-wrapper">
          <PouchInput
            type="text"
            meetId={meet._id}
            documentId={meet.entryConfig._id}
            name="stripePublicKey"
            value={get(meet.entryConfig, "stripePublicKey")}
          />
        </div>
      </div>
      <div className="entry-row">
        <label>Stripe Secret Key:</label>
        <div className="input-wrapper">
          <PouchInput
            type="text"
            meetId={meet._id}
            documentId={meet.restricted._id}
            name="stripeSecretKey"
            value={get(meet.restricted, "stripeSecretKey")}
          />
        </div>
      </div>
      <div className="error-message">{stripeErrors()}</div>
      <div>{stripeMode()}</div>

      <hr />

      <h3>Custom Questions</h3>
      <table>
        <thead>
          <tr>
            <th>Question</th>
            <th>Type</th>
            <th>Required</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {map(meet.entryConfig.customQuestions, (question, index) => {
            return (
              <tr key={index}>
                <td>
                  <PouchInput
                    type="text"
                    meetId={meet._id}
                    documentId={meet.entryConfig._id}
                    name={`customQuestions.${index}.text`}
                    value={get(meet.entryConfig, [
                      "customQuestions",
                      index,
                      "text",
                    ])}
                  />
                </td>
                <td>
                  <PouchSelect
                    meet={meet}
                    document={meet.entryConfig}
                    name={`customQuestions.${index}.type`}
                    value={get(meet.entryConfig, [
                      "customQuestions",
                      index,
                      "type",
                    ])}
                    options={getCustomQuestionTypeOptions}
                    clearable={false}
                  />
                </td>
                <td>
                  <PouchCheckbox
                    meetId={meet._id}
                    documentId={meet.entryConfig._id}
                    name={`customQuestions.${index}.required`}
                    value={get(meet.entryConfig, [
                      "customQuestions",
                      index,
                      "required",
                    ])}
                  />
                </td>
                <td>
                  <button onClick={() => removeQuestion(question)}>
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <button onClick={addQuestion}>Add Custom Question</button>
      </div>

      <hr />

      <h3>Sale Items</h3>
      <table className="sale-items">
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Item Cost</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {map(meet.entryConfig.items, (item, index) => {
            return (
              <tr key={index}>
                <td>
                  <PouchInput
                    type="text"
                    meetId={meet._id}
                    documentId={meet.entryConfig._id}
                    name={`items.${index}.name`}
                    value={get(meet.entryConfig, ["items", index, "name"])}
                  />
                </td>
                <td>
                  <PouchInput
                    type="number"
                    meetId={meet._id}
                    documentId={meet.entryConfig._id}
                    name={`items.${index}.cost`}
                    value={get(meet.entryConfig, ["items", index, "cost"])}
                  />
                </td>
                <td>
                  <button onClick={() => removeItem(item)}>
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <button onClick={addItem}>Add Item For Sale</button>
      </div>
    </div>
  );
};

export default Registration;
