import React from "react";
import map from "lodash/map";
import round from "lodash/round";
import startsWith from "lodash/startsWith";

import {
  getTotal,
  getBodyWeightAdjustedTotal,
  getBodyWeightAndAgeAdjustedTotal,
  getIpfPoints,
  getIpfAndAgePoints,
  getDotsPoints,
  getDotsAndAgePoints,
  getSchwartzMalonePoints,
  getSchwartzMaloneAndAgePoints,
  getGlossbrennerPoints,
  getGlossbrennerAndAgePoints,
  getParaDotsPoints,
  getKPoints,
  getPercentOfRecordPoints,
  getDivisionDoc,
} from "util/lifterHelper";

const scoreFunctions = {
  total: getTotal,
  forecastedTotal: getTotal,
  wilks: getBodyWeightAdjustedTotal,
  forecastedWilks: getBodyWeightAdjustedTotal,
  wilksAge: getBodyWeightAndAgeAdjustedTotal,
  forecastedWilksAge: getBodyWeightAndAgeAdjustedTotal,
  ipfPoints: getIpfPoints,
  forecastedIpfPoints: getIpfPoints,
  ipfAgePoints: getIpfAndAgePoints,
  forecastedIpfAgePoints: getIpfAndAgePoints,
  dotsPoints: getDotsPoints,
  forecastedDotsPoints: getDotsPoints,
  dotsAgePoints: getDotsAndAgePoints,
  forecastedDotsAgePoints: getDotsAndAgePoints,
  schwartzMalonePoints: getSchwartzMalonePoints,
  schwartzMaloneAndAgePoints: getSchwartzMaloneAndAgePoints,
  glossbrennerPoints: getGlossbrennerPoints,
  glossbrennerAndAgePoints: getGlossbrennerAndAgePoints,
  paraDotsPoints: getParaDotsPoints,
  forecastedParaDotsPoints: getParaDotsPoints,
  percentOfRecord: getPercentOfRecordPoints,
  forecastedPercentOfRecord: getPercentOfRecordPoints,
  kPoints: getKPoints,
};

const ScoreCell = ({ data, column, meet, style }) => {
  const lifter = data;
  if (lifter.row === "header") {
    return (
      <div style={style} className="table-cell-inner">
        {column.label}
      </div>
    );
  } else {
    return (
      <div style={style} className="table-cell-inner">
        {map(data.divisions, (lifterDivision, index) => {
          if (!lifterDivision.divisionId) {
            return null;
          }

          const division = getDivisionDoc(lifterDivision.divisionId, meet);
          if (!division) {
            return null;
          }

          if (division.hideOnBoard) {
            return null;
          }

          const scoreFunc = scoreFunctions[column.key];

          return (
            <div
              key={`${lifterDivision.divisionId}-${index}`}
              className="cell-row"
            >
              {round(
                scoreFunc(
                  lifter,
                  lifterDivision.divisionId,
                  meet,
                  startsWith(column.key, "forecasted")
                ),
                3
              )}
            </div>
          );
        })}
      </div>
    );
  }
};

export default ScoreCell;
