import Papa from "papaparse";
import {
  Attempt,
  Division,
  EligibleRecord,
  EligibleRecordWithWeight,
  EquipmentLevel,
  ExpandedRecord,
  Gender,
  LiftName,
  Lifter,
  Meet,
  RecordCompetitionType,
  RecordCompetitionTypeAbbr,
  RecordDivisionCode,
  RecordLift,
  RecordLiftAbbr,
  RecordLocation,
  RecordLocationAbbr,
  RecordTestedAbbr,
  RecordWeightClassName,
  Records,
} from "types";
import { presentStringAsDownload } from "./exportData";
import { getBaseDivisions, standardDivisionAge } from "./standardDivisions";
import { getGenderOptions, getRawOrEquippedOptions } from "./options";
import { federationConfigs } from "./federations";
import setWith from "lodash/setWith";
import isEqual from "lodash/isEqual";
import find from "lodash/find";
import each from "lodash/each";
import some from "lodash/some";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import uniqWith from "lodash/uniqWith";
import { isLbsMeet } from "./meetHelper";
import { getDivisionDoc, getTotal, isCompetingInLift } from "./lifterHelper";
import { set as setImmutable } from "object-path-immutable";

export type ExpandedRecords = ExpandedRecord[];

export const getExpandedRecords = (meet: Meet): ExpandedRecords => {
  const expandedRecords: ExpandedRecords = [];
  const records = meet.records;
  if (!records) {
    return expandedRecords;
  }

  each(records.data, (equipmentLevels, gender) => {
    each(equipmentLevels, (drugTests, equipmentLevel) => {
      each(drugTests, (divisionCodes, drugTestAbbr) => {
        each(divisionCodes, (weightClassNames, divisionCode) => {
          each(weightClassNames, (competitionTypes, weightClassName) => {
            each(competitionTypes, (lifts, competitionTypeAbbr) => {
              each(lifts, (locations, liftAbbr) => {
                each(locations, (recordWeight, locationAbbr) => {
                  const drugTested =
                    drugTestAbbr === "U" ? "UNTESTED" : "TESTED";
                  const competitionType =
                    mapCompFromAbbr[
                      competitionTypeAbbr as RecordCompetitionTypeAbbr
                    ];
                  const lift = mapLiftsFromAbbr[liftAbbr as RecordLiftAbbr];
                  const location = mapLocationFromAbbr(
                    locationAbbr as RecordLocationAbbr
                  );

                  if (location && recordWeight && lift && competitionType) {
                    expandedRecords.push({
                      gender: gender as Gender,
                      equipmentLevel: equipmentLevel as EquipmentLevel,
                      drugTested,
                      divisionCode: divisionCode as RecordDivisionCode,
                      weightClass: weightClassName as RecordWeightClassName,
                      competitionType,
                      lift,
                      location,
                      recordWeight: recordWeight,
                    });
                  }
                });
              });
            });
          });
        });
      });
    });
  });

  return expandedRecords;
};

export const getAllExpandedRecordsForFederation = (meet: Meet) => {
  const records: ExpandedRecords = [];
  if (!meet.federation) {
    return records;
  }
  const baseDivisions = getBaseDivisions(meet);
  const genderOptions = getGenderOptions(null, meet);
  const equipmentLevelOptions = getRawOrEquippedOptions(null, meet);

  const recordOptions = federationConfigs[meet.federation].recordOptions;

  const locations: RecordLocation[] = [
    "WORLD",
    "NATIONAL",
    // "STATE:AL",
    // "STATE:AK",
    // "STATE:AZ",
    // "STATE:AR",
    // "STATE:CA",
    // "STATE:CO",
    // "STATE:CT",
    // "STATE:DE",
    // "STATE:DC",
    // "STATE:FL",
    // "STATE:GA",
    // "STATE:HI",
    // "STATE:ID",
    // "STATE:IL",
    // "STATE:IN",
    // "STATE:IA",
    // "STATE:KS",
    // "STATE:KY",
    // "STATE:LA",
    // "STATE:ME",
    // "STATE:MD",
    // "STATE:MA",
    // "STATE:MI",
    // "STATE:MN",
    // "STATE:MS",
    // "STATE:MO",
    // "STATE:MT",
    // "STATE:NE",
    // "STATE:NV",
    // "STATE:NH",
    // "STATE:NJ",
    // "STATE:NM",
    // "STATE:NY",
    // "STATE:NC",
    // "STATE:ND",
    // "STATE:OH",
    // "STATE:OK",
    // "STATE:OR",
    // "STATE:PA",
    // "STATE:RI",
    // "STATE:SC",
    // "STATE:SD",
    // "STATE:TN",
    // "STATE:TX",
    // "STATE:UT",
    // "STATE:VT",
    // "STATE:VA",
    // "STATE:WA",
    // "STATE:WV",
    // "STATE:WI",
    // "STATE:WY",
  ];

  each(genderOptions, (genderOption) => {
    const gender = genderOption.value;
    each(equipmentLevelOptions, (equipmentLevelOption) => {
      const equipmentLevel = equipmentLevelOption.value;
      each(baseDivisions, (divisionConfig) => {
        const divisionCode = divisionConfig.code;
        if (!divisionConfig.records) {
          return;
        }
        const drugTested = divisionConfig.name.includes("Untested")
          ? "UNTESTED"
          : "TESTED";

        each(divisionConfig.weightClasses[gender], (weightClass) => {
          const weightClassName = weightClass.name;
          each(
            ["FULL-POWER", "SINGLE-LIFT", "PUSH-PULL"] as const,
            (competitionType) => {
              const lifts = recordOptions[competitionType];
              if (!lifts) {
                return;
              }
              each(lifts, (lift) => {
                if (
                  ["RAW_WITH_WRAPS", "CLASSIC_RAW"].includes(equipmentLevel) &&
                  competitionType === "PUSH-PULL"
                ) {
                  // if you are push/pull wraps divisions doesn't make sense.
                  return;
                }

                if (
                  ["RAW_WITH_WRAPS", "CLASSIC_RAW"].includes(equipmentLevel) &&
                  competitionType === "SINGLE-LIFT" &&
                  (lift === "BENCH-PRESS" || lift === "DEADLIFT")
                ) {
                  // if you are not squatting wraps divisions doesn't make sense.
                  return;
                }

                each(locations, (location) => {
                  records.push({
                    gender,
                    equipmentLevel,
                    drugTested,
                    divisionCode,
                    weightClass: weightClassName,
                    competitionType,
                    lift,
                    location,
                    recordWeight: null,
                  });
                });
              });
            }
          );
        });
      });
    });
  });

  return records;
};

export const getStandardDivisionCode = function ({
  lifter,
  meet,
}: {
  lifter: Lifter;
  meet: Meet;
}): string | null {
  const baseDivisions = getBaseDivisions(meet);
  const divisionAge = standardDivisionAge(lifter, meet);
  if (divisionAge && divisionAge >= 8) {
    const standardDivision = baseDivisions.find((division) => {
      return (
        division.default &&
        division.low &&
        division.high &&
        divisionAge >= division.low &&
        divisionAge <= division.high
      );
    });

    if (standardDivision) {
      return standardDivision.code;
    }
  }

  return null;
};

export const getLifterWeightClass = function ({
  lifter,
  divisionCode,
  meet,
}: {
  lifter: Lifter;
  divisionCode: string;
  meet: Meet;
}) {
  const baseDivisions = getBaseDivisions(meet);
  const division = find(baseDivisions, { code: divisionCode });

  if (lifter.gender && division && division.name) {
    const weightClasses = division.weightClasses[lifter.gender];
    const bodyWeight = lifter.bodyWeight;
    if (!bodyWeight) {
      return;
    }

    return find(weightClasses, (wc) => {
      let maxWeight = wc.maxWeight;
      if (isLbsMeet(meet)) {
        maxWeight = wc.lbsMaxWeight;
      }
      return bodyWeight <= maxWeight;
    });
  }
};

// This is only intended to be used in situations where we don't care about the weight class match
// such as when deciding what to import.
const getAllEligibleRecords = ({ meet }: { meet: Meet }) => {
  let allEligibleRecords: EligibleRecord[] = [];
  each(meet.lifters, (lifter) => {
    // if a lifter doesn't have a body weight anything will do. We will match all weight classes later on anyways.
    const forLifter = getEligibleRecordsForLifter({
      lifter: { ...lifter, bodyWeight: lifter.bodyWeight || 999 },
      meet,
    });
    allEligibleRecords = allEligibleRecords.concat(forLifter);
  });

  return uniqWith(allEligibleRecords, isEqual);
};

const meetHasRecordCategoryLifters = ({
  allEligibleRecords,
  record,
}: {
  allEligibleRecords: EligibleRecord[];
  record: EligibleRecord;
}) => {
  return some(allEligibleRecords, (eligibleRecord) => {
    // compare all except for weightClass, we want to import all weight classes in case someone misses weight
    return (
      eligibleRecord.gender === record.gender &&
      eligibleRecord.equipmentLevel === record.equipmentLevel &&
      eligibleRecord.tested === record.tested &&
      eligibleRecord.divisionCode === record.divisionCode &&
      eligibleRecord.competitionType === record.competitionType &&
      eligibleRecord.lift === record.lift &&
      eligibleRecord.location === record.location
    );
  });
};

export const convertRecordsCsvToJson = ({
  meet,
  records,
}: {
  meet: Meet;
  records: ExpandedRecords;
}) => {
  const jsonRecords: Records = {};
  const discarded: ExpandedRecords = [];
  let count = 0;

  const allEligibleRecords = getAllEligibleRecords({ meet });
  records.forEach((r) => {
    const gender = r.gender;
    const equipmentLevel = r.equipmentLevel;
    const drugTested = r.drugTested === "TESTED" ? "T" : "U";
    const divisionCode = r.divisionCode;
    const weightClass = r.weightClass;
    const competitionType = mapComp[r.competitionType];
    const lift = mapLifts[r.lift];
    const location = mapLocation(r.location);
    const recordWeight = r.recordWeight;
    if (
      gender &&
      equipmentLevel &&
      drugTested &&
      divisionCode &&
      weightClass &&
      competitionType &&
      lift &&
      location &&
      recordWeight &&
      meetHasRecordCategoryLifters({
        allEligibleRecords,
        record: {
          gender,
          equipmentLevel,
          tested: drugTested,
          divisionCode,
          weightClass,
          competitionType,
          lift,
          location,
        },
      })
    ) {
      setWith(
        jsonRecords,
        [
          gender,
          equipmentLevel,
          drugTested,
          divisionCode,
          weightClass,
          competitionType,
          lift,
          location,
        ],
        recordWeight,
        Object
      );
      count = count + 1;
      if (count > 50_000) {
        throw new Error(
          "You've reach the maximum relevant records count of 50,000. You'll need to reduce the size of your records import to continue."
        );
      }
    } else {
      discarded.push(r);
    }
  });

  return { data: jsonRecords, discarded };
};

// const printSizes = async (jsonRecords: any) => {
//   console.log(jsonRecords);
//   const dataJsonString = JSON.stringify(jsonRecords);
//   console.log("size", byteSize(dataJsonString) / 1024 / 1024);
//   const start = Date.now();
//   const compressedBytes = await compress(dataJsonString);
//   const end = Date.now();
//   console.log("elapse", end - start);
//   console.log("compressed size", compressedBytes.byteLength / 1024 / 1024);
// };
// const byteSize = (str: string) => new Blob([str]).size;

const mapLocation = (
  location: RecordLocation | undefined | null
): RecordLocationAbbr | null => {
  if (!location) {
    return null;
  }
  if (location === "WORLD") {
    return "W";
  }
  if (location === "NATIONAL") {
    return "N";
  }
  // if (location.startsWith("STATE:")) {
  //   const parts = location.split(":");
  //   return `S:${parts[1]}`;
  // }
  return null;
};

const mapLocationFromAbbr = (
  location: RecordLocationAbbr | undefined | null
): RecordLocation | null => {
  if (!location) {
    return null;
  }
  if (location === "W") {
    return "WORLD";
  }
  if (location === "N") {
    return "NATIONAL";
  }

  return null;
};

const mapComp: Record<RecordCompetitionType, RecordCompetitionTypeAbbr> = {
  "FULL-POWER": "FP" as const,
  "SINGLE-LIFT": "SL" as const,
  "PUSH-PULL": "PP" as const,
};

const mapCompFromAbbr: Record<
  RecordCompetitionTypeAbbr,
  RecordCompetitionType
> = {
  FP: "FULL-POWER" as const,
  SL: "SINGLE-LIFT" as const,
  PP: "PUSH-PULL" as const,
};

const mapLifts: Record<RecordLift, RecordLiftAbbr> = {
  SQUAT: "S" as const,
  "BENCH-PRESS": "B" as const,
  DEADLIFT: "D" as const,
  TOTAL: "T" as const,
};

const mapLiftsFromAbbr: Record<RecordLiftAbbr, RecordLift> = {
  S: "SQUAT" as const,
  B: "BENCH-PRESS" as const,
  D: "DEADLIFT" as const,
  T: "TOTAL" as const,
};

export const exportRecords = (meet: Meet) => {
  const csvObject: any = getExpandedRecords(meet);

  csvObject.unshift({
    gender: "gender",
    equipmentLevel: "equipmentLevel",
    drugTested: "drugTested",
    divisionCode: "divisionCode",
    weightClass: "weightClass",
    competitionType: "competitionType",
    lift: "lift",
    location: "location",
    recordWeight: "recordWeight",
  });

  const csvString = Papa.unparse(csvObject, { quotes: true, header: false });
  // console.log("size csvString", byteSize(csvString) / 1024 / 1024);
  const fileName = "records";
  presentStringAsDownload(csvString, meet, fileName);
};

export const exportAllExpandedRecordsForFederation = (meet: Meet) => {
  const csvObject: any = getAllExpandedRecordsForFederation(meet);

  csvObject.unshift({
    gender: "gender",
    equipmentLevel: "equipmentLevel",
    drugTested: "drugTested",
    divisionCode: "divisionCode",
    weightClass: "weightClass",
    competitionType: "competitionType",
    lift: "lift",
    location: "location",
    recordWeight: "recordWeight",
  });

  const csvString = Papa.unparse(csvObject, { quotes: true, header: false });
  // console.log("size csvString", byteSize(csvString) / 1024 / 1024);
  const fileName = "records_template";
  presentStringAsDownload(csvString, meet, fileName);
};

export const getEligibleRecordsForLifter = ({
  lifter,
  meet,
}: {
  lifter: Lifter;
  meet: Meet;
}) => {
  const eligibleRecords: EligibleRecord[] = [];
  const gender = lifter.gender;
  if (!gender) {
    return eligibleRecords;
  }

  let equipmentLevel: EquipmentLevel | null = null;
  let tested: RecordTestedAbbr | null = null;
  const locations: RecordLocationAbbr[] = ["W", "N"]; // TODO: state and other region support

  each(lifter.divisions, (ld) => {
    const divisionDoc = getDivisionDoc(ld.divisionId, meet);
    if (!divisionDoc) {
      return eligibleRecords;
    }

    if (!equipmentLevel) {
      equipmentLevel = divisionDoc.rawOrEquipped;
    }

    if (!tested) {
      tested = divisionDoc.name?.toLowerCase()?.includes("untested")
        ? "U"
        : "T";
    }

    const competitionTypes: RecordCompetitionTypeAbbr[] = ["SL"];
    if (
      divisionDoc.lifts.squat &&
      divisionDoc.lifts.bench &&
      divisionDoc.lifts.dead
    ) {
      competitionTypes.push("FP");
    }

    if (divisionDoc.lifts.bench && divisionDoc.lifts.dead) {
      competitionTypes.push("PP");
    }

    const divisionCode = divisionDoc.usaplDivisionCode;

    if (!divisionCode) {
      return eligibleRecords;
    }
    const weightClassConfig = getLifterWeightClass({
      lifter,
      divisionCode,
      meet,
    });
    if (!weightClassConfig) {
      return eligibleRecords;
    }

    each(competitionTypes, (competitionType) => {
      let lifts: RecordLiftAbbr[] = [];
      if (competitionType === "FP") {
        lifts = ["S", "B", "D", "T"];
      }
      if (competitionType === "PP") {
        lifts = ["B", "D", "T"];
      }

      if (competitionType === "SL") {
        if (divisionDoc.lifts.squat) {
          lifts.push("S");
        }
        if (divisionDoc.lifts.bench) {
          lifts.push("B");
        }
        if (divisionDoc.lifts.dead) {
          lifts.push("D");
        }
      }

      each(lifts, (lift) => {
        each(locations, (location) => {
          eligibleRecords.push({
            gender,
            equipmentLevel: divisionDoc.rawOrEquipped,
            tested: divisionDoc.name?.toLowerCase()?.includes("untested")
              ? "U"
              : "T",
            divisionCode,
            weightClass: weightClassConfig.name,
            competitionType,
            lift,
            location,
          });
        });
      });
    });
  });

  const standardDivisionCode = getStandardDivisionCode({ lifter, meet });
  if (standardDivisionCode && tested && equipmentLevel) {
    const weightClassConfig = getLifterWeightClass({
      lifter,
      divisionCode: standardDivisionCode,
      meet,
    });
    if (weightClassConfig) {
      const isSquatting = isCompetingInLift(lifter, "squat", meet);
      const isBenching = isCompetingInLift(lifter, "bench", meet);
      const isDeadlifting = isCompetingInLift(lifter, "dead", meet);
      const competitionTypes: RecordCompetitionTypeAbbr[] = ["SL"];
      if (isSquatting && isBenching && isDeadlifting) {
        competitionTypes.push("FP");
      }
      if (isBenching && isDeadlifting) {
        competitionTypes.push("PP");
      }
      each(competitionTypes, (competitionType) => {
        let lifts: RecordLiftAbbr[] = [];
        if (competitionType === "FP") {
          lifts = ["S", "B", "D", "T"];
        }
        if (competitionType === "PP") {
          lifts = ["B", "D", "T"];
        }

        if (competitionType === "SL") {
          if (isSquatting) {
            lifts.push("S");
          }
          if (isBenching) {
            lifts.push("B");
          }
          if (isDeadlifting) {
            lifts.push("D");
          }
        }
        each(lifts, (lift) => {
          each(locations, (location) => {
            if (!equipmentLevel || !tested) {
              return;
            }
            eligibleRecords.push({
              gender,
              equipmentLevel,
              tested,
              divisionCode: standardDivisionCode,
              weightClass: weightClassConfig.name,
              competitionType,
              lift,
              location,
            });
            if (standardDivisionCode !== "O") {
              eligibleRecords.push({
                gender,
                equipmentLevel,
                tested,
                divisionCode: "O",
                weightClass: weightClassConfig.name,
                competitionType,
                lift,
                location,
              });
            }
          });
        });
      });
    }
  }

  // TODO: for usapl, maybe others?, add the next age cat

  return uniqWith(eligibleRecords, areEligibleRecordsEqual);
};

const areEligibleRecordsEqual = (one: EligibleRecord, two: EligibleRecord) => {
  return (
    one.competitionType === two.competitionType &&
    one.divisionCode === two.divisionCode &&
    one.equipmentLevel === two.equipmentLevel &&
    one.gender === two.gender &&
    one.lift === two.lift &&
    one.location === two.location &&
    one.tested === two.tested &&
    one.weightClass === two.weightClass
  );
};

const liftsMap: Record<RecordLiftAbbr, LiftName[]> = {
  S: ["squat"],
  B: ["bench"],
  D: ["dead"],
  // T: ["squat", "bench", "dead"], // TODO: only show possible total breaking on deadlift?
  T: ["dead"],
};

export const getRecordWeightForEligibleRecord = ({
  attemptId,
  meet,
  eligibleRecord,
  eligibleRecordsByLifter,
}: {
  attemptId?: string;
  meet: Meet;
  eligibleRecord: EligibleRecord;
  eligibleRecordsByLifter?: Record<string, EligibleRecord[]>;
}): number | null => {
  if (!meet.records) {
    return null;
  }
  let recordWeight = get(
    meet.records.data,
    [
      eligibleRecord.gender,
      eligibleRecord.equipmentLevel,
      eligibleRecord.tested,
      eligibleRecord.divisionCode,
      eligibleRecord.weightClass,
      eligibleRecord.competitionType,
      eligibleRecord.lift,
      eligibleRecord.location,
    ],
    null
  );

  if (!recordWeight) {
    return null;
  }

  for (const lifter of Object.values(meet.lifters)) {
    const eligibleRecordsToCompare = eligibleRecordsByLifter
      ? eligibleRecordsByLifter[lifter._id]
      : getEligibleRecordsForLifter({
          lifter,
          meet,
        });

    if (!eligibleRecordsToCompare) {
      return null;
    }

    for (const eligibleRecordToCompare of eligibleRecordsToCompare) {
      if (!areEligibleRecordsEqual(eligibleRecordToCompare, eligibleRecord)) {
        continue;
      }

      const lifts = liftsMap[eligibleRecordToCompare.lift];
      for (const liftName of lifts) {
        const attempts = get(lifter, ["lifts", liftName]);
        for (const attempt of Object.values(attempts)) {
          if (attempt.result !== "good") {
            continue;
          }
          const compareAttemptWeight = attempt.weight;
          if (!compareAttemptWeight) {
            continue;
          }
          if (attemptId === attempt._id) {
            continue;
          }

          if (compareAttemptWeight > recordWeight) {
            recordWeight = compareAttemptWeight;
          }

          if (eligibleRecordToCompare.lift === "T") {
            const liftsOverride: Record<LiftName, boolean> | undefined =
              eligibleRecordToCompare.competitionType === "FP"
                ? { squat: true, bench: true, dead: true }
                : eligibleRecordToCompare.competitionType === "PP"
                ? { squat: false, bench: true, dead: true }
                : undefined;

            const totalToCompare = getTotal(
              lifter,
              "",
              meet,
              false,
              liftsOverride
            );

            if (totalToCompare > recordWeight) {
              recordWeight = totalToCompare;
            }
          }
        }
      }
    }
  }
  return recordWeight;
};

export const attemptIsRecord = ({
  lifter,
  attempt,
  meet,
  eligibleRecordsByLifter,
}: {
  lifter: Lifter;
  attempt: Attempt;
  meet: Meet;
  eligibleRecordsByLifter: Record<string, EligibleRecord[]>;
}): EligibleRecordWithWeight[] | null => {
  const recordAttempts: EligibleRecordWithWeight[] = [];
  const attemptWeight = attempt.weight;
  const attemptLiftName = attempt.liftName;
  const attemptNumber = attempt.attemptNumber;
  if (
    !attemptWeight ||
    attempt.result === "bad" ||
    !attemptLiftName ||
    !attemptNumber
  ) {
    return null;
  }
  const eligibleRecords = eligibleRecordsByLifter[lifter._id];
  if (!eligibleRecords) {
    return null;
  }

  eligibleRecords.forEach((eligibleRecord) => {
    const liftMatch =
      (eligibleRecord.lift === "S" && attempt.liftName === "squat") ||
      (eligibleRecord.lift === "B" && attempt.liftName === "bench") ||
      (eligibleRecord.lift === "D" && attempt.liftName === "dead");

    let recordWeight;
    if (liftMatch || eligibleRecord.lift === "T") {
      recordWeight = getRecordWeightForEligibleRecord({
        attemptId: attempt._id,
        meet,
        eligibleRecord,
        eligibleRecordsByLifter,
      });
    }

    if (!recordWeight) {
      return;
    }

    const eligibleRecordWithWeight: EligibleRecordWithWeight = {
      ...eligibleRecord,
      recordWeight,
    };

    if (liftMatch) {
      if (attemptWeight > recordWeight) {
        recordAttempts.push(eligibleRecordWithWeight);
      }
    }

    if (eligibleRecordWithWeight.lift === "T") {
      // calculate total if this attempt is successful
      const liftsOverride: Record<LiftName, boolean> | undefined =
        eligibleRecordWithWeight.competitionType === "FP"
          ? { squat: true, bench: true, dead: true }
          : eligibleRecordWithWeight.competitionType === "PP"
          ? { squat: false, bench: true, dead: true }
          : undefined;
      const lifterWithThisAttemptGood = setImmutable(
        lifter,
        ["lifts", attemptLiftName, attemptNumber, "result"],
        "good"
      );
      const possibleTotal = getTotal(
        lifterWithThisAttemptGood,
        "",
        meet,
        false,
        liftsOverride
      );

      if (possibleTotal > recordWeight) {
        recordAttempts.push(eligibleRecordWithWeight);
      }
    }
  });

  return isEmpty(recordAttempts) ? null : recordAttempts;
};

export const formatEligibleRecord = ({
  record,
}: {
  record: EligibleRecordWithWeight;
}) => {
  return `${record.location} : ${record.competitionType} : ${record.lift} : ${
    record.gender
  } : ${record.equipmentLevel} : ${record.tested === "U" ? "Untested :" : ""}${
    record.divisionCode
  } : w${record.weightClass} = ${record.recordWeight}`;
};

// Unlike other places that look at the current record,
// this looks at the imported record and does not adjust if the record is broken during the meet.
export const getPercentOfRecordEligibleRecord = ({
  division,
  lifter,
  meet,
}: {
  division: Division | undefined;
  lifter: Lifter;
  meet: Meet;
}): EligibleRecordWithWeight | null => {
  const records = meet.records;
  const equipmentLevel = division?.rawOrEquipped;
  const gender = lifter.gender;

  if (!records || !equipmentLevel || !gender || !division) {
    return null;
  }

  const tested = division.name?.toLowerCase()?.includes("untested") ? "U" : "T";
  const divisionCode = "O";
  const weightClassConfig = getLifterWeightClass({
    lifter,
    divisionCode,
    meet,
  });

  if (!weightClassConfig) {
    return null;
  }

  const squatOnly =
    division.lifts.squat && !division.lifts.bench && !division.lifts.dead;
  const benchOnly =
    !division.lifts.squat && division.lifts.bench && !division.lifts.dead;
  const deadOnly =
    !division.lifts.squat && !division.lifts.bench && division.lifts.dead;
  let competitionType: RecordCompetitionTypeAbbr | null = null;
  if (division.lifts.squat && division.lifts.bench && division.lifts.dead) {
    competitionType = "FP";
  } else if (division.lifts.bench && division.lifts.dead) {
    competitionType = "PP";
  } else if (squatOnly || benchOnly || deadOnly) {
    competitionType = "SL";
  }

  if (!competitionType) {
    return null;
  }

  const lift = squatOnly ? "S" : benchOnly ? "B" : deadOnly ? "D" : "T";
  const location = "N"; // TODO make this configurable per meet N or W;

  const recordWeight = get(
    records.data,
    [
      gender,
      equipmentLevel,
      tested,
      divisionCode,
      weightClassConfig.name,
      competitionType,
      lift,
      location,
    ],
    null
  );

  const record: EligibleRecordWithWeight = {
    gender,
    equipmentLevel,
    tested,
    divisionCode,
    weightClass: weightClassConfig.name,
    competitionType,
    lift,
    location,
    recordWeight,
  };

  return record;
};
