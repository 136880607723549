import each from "lodash/each";
import every from "lodash/every";
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import findLast from "lodash/findLast";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";
import toNumber from "lodash/toNumber";

import { differenceInYears, startOfYear } from "date-fns";

import {
  wilksCoef,
  ageCoef,
  ipfFormula,
  dotsFormula,
  schwartzMaloneCoef,
  paraDotsFormula,
  ahPoints,
  glossbrennerCoef,
  kPointsFormula,
  percentOfRecord,
} from "./coefficients";
import { parseDateString } from "util/dateHelper";
import { toKg } from "./conversions";

import { isLbsMeet } from "./meetHelper";
import {
  Attempt,
  Division,
  LiftName,
  Lifter,
  LifterDivision,
  Meet,
  WeightClass,
} from "types";

export const getEmptyLifterDivision = (): LifterDivision => {
  return {
    rawOrEquipped: undefined,
    divisionId: undefined,
    declaredAwardsWeightClassId: undefined,
  };
};

export const getDivisionDoc = function (
  divisionId: string | undefined | null,
  meet: Meet
) {
  if (!divisionId) {
    return undefined;
  }
  return get(meet, ["divisions", divisionId]);
};

export const getLifterDeclaredAwardsWeightClass = function (
  divisionDoc: Division | undefined,
  lifterDivision: LifterDivision | undefined
) {
  if (!divisionDoc || !lifterDivision?.declaredAwardsWeightClassId) {
    return {};
  }
  return get(
    divisionDoc,
    ["weightClasses", lifterDivision.declaredAwardsWeightClassId],
    {}
  );
};

export const getLifterAwardsWeightClassDoc = function (
  meet: Meet,
  lifter: Lifter,
  lifterDivision?: LifterDivision
) {
  if (!lifterDivision) {
    return {};
  }
  const divisionDoc = getDivisionDoc(lifterDivision.divisionId, meet);
  const bodyWeight = lifter.bodyWeight;
  if (bodyWeight && divisionDoc) {
    const weightClasses = sortBy(divisionDoc.weightClasses, "maxWeight");

    let lifterWeightClass: WeightClass | undefined;
    every(weightClasses, function (weightClass) {
      if (!lifterWeightClass) {
        if (bodyWeight <= toNumber(weightClass.maxWeight)) {
          lifterWeightClass = weightClass;
          return false; // break loop
        }
      }

      return true; // continue loop
    });
    return lifterWeightClass || { name: "NO MATCH" };
  } else if (get(lifterDivision, "declaredAwardsWeightClassId")) {
    return getLifterDeclaredAwardsWeightClass(divisionDoc, lifterDivision);
  }

  return {};
};

export const isCompetingInLift = function (
  lifter: Lifter,
  liftName: LiftName,
  meet: Meet
) {
  return !!find(lifter.divisions, (division) => {
    const divisionDoc = getDivisionDoc(division.divisionId, meet);
    return get(divisionDoc, ["lifts", liftName]);
  });
};

export const isCurrentLifter = function (
  lifter: Lifter,
  meet: Meet,
  platformId: string
) {
  const currentAttemptId = get(meet, [
    "platforms",
    platformId,
    "currentAttemptId",
  ]);
  let isCurrentLifter = false;
  each(lifter.lifts, (lift) => {
    every(lift, (attempt) => {
      if (attempt && attempt._id === currentAttemptId) {
        isCurrentLifter = true;
        return false; // break loop
      }

      return true; // continue loop
    });
  });

  return isCurrentLifter;
};

export const getLastSuccessfulLiftTimestamp = function (lifter: Lifter) {
  const lifts = get(lifter, "lifts");

  let lastSuccessfulLiftTimestamp = "";

  each(["squat", "bench", "dead"], (liftName) => {
    // Since liftersAttempts is an object ensure order here
    const orderedAttemptArray = [
      get(lifts, [liftName, "1"]),
      get(lifts, [liftName, "2"]),
      get(lifts, [liftName, "3"]),
    ];

    const lastSuccessfulLift = findLast(orderedAttemptArray, (attempt) => {
      return attempt && attempt.weight && attempt.result === "good";
    });

    if (get(lastSuccessfulLift, "changes")) {
      const changeRecord = findLast(lastSuccessfulLift.changes, (change) => {
        return change.attribute === "result" && change.value === "good";
      });

      if (get(changeRecord, "timeStamp")) {
        lastSuccessfulLiftTimestamp = changeRecord.timeStamp;
      }
    }
  });

  return lastSuccessfulLiftTimestamp;
};

export const getBestLift = function (
  liftName: LiftName,
  lifter: Lifter,
  forecasted?: boolean
): Attempt | undefined {
  const liftersAttempts = get(lifter, ["lifts", liftName]);

  // Since liftersAttempts is an object ensure order here
  const orderedAttemptArray = [
    get(liftersAttempts, "1"),
    get(liftersAttempts, "2"),
    get(liftersAttempts, "3"),
  ];

  return findLast(orderedAttemptArray, (attempt) => {
    return (
      attempt &&
      attempt.weight &&
      (attempt.result === "good" || (!attempt.result && forecasted))
    );
  }) as Attempt;
};

export const getTotal = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean,
  liftsOverride?: Record<LiftName, boolean> | undefined
) {
  const runningTotal = !!meet.runningTotal;
  let total = 0;
  let isBombOut = false;

  const lifts = liftsOverride
    ? liftsOverride
    : getDivisionDoc(divisionId, meet)?.lifts;

  if (lifts) {
    every(lifts, (isCompetingInLift, liftName) => {
      if (runningTotal) {
        // override logic for bomb out so that we can track total as we go.
        const liftersAttempts = get(lifter, ["lifts", liftName]);

        if (
          liftersAttempts?.["1"]?.result === "bad" &&
          liftersAttempts?.["2"]?.result === "bad" &&
          liftersAttempts?.["3"]?.result === "bad"
        ) {
          isBombOut = true;
          return false; // break loop
        }
      }

      if (isCompetingInLift) {
        const bestLift = getBestLift(liftName as LiftName, lifter, forecasted);
        if (bestLift && bestLift.weight) {
          total += bestLift.weight;
        } else {
          if (!runningTotal) {
            isBombOut = true;
            return false; // break loop
          }
        }
      }

      return true; // continue loop
    });
  }

  if (isBombOut) {
    return 0;
  }
  return total;
};

export const getSubtotal = function (lifter: Lifter, meet: Meet) {
  // only show subtotal if doing all three lifts
  if (
    isCompetingInLift(lifter, "squat", meet) &&
    isCompetingInLift(lifter, "bench", meet) &&
    isCompetingInLift(lifter, "dead", meet)
  ) {
    const bestSquatAttempt = getBestLift("squat", lifter);
    const bestBenchAttempt = getBestLift("bench", lifter);
    if (
      bestSquatAttempt &&
      bestBenchAttempt &&
      bestSquatAttempt.weight &&
      bestBenchAttempt.weight
    ) {
      return bestSquatAttempt.weight + bestBenchAttempt.weight;
    }
  }

  return "";
};

export const getBodyWeightAdjustedTotal = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getBodyWeightAdjustedTotal(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getBodyWeightAdjustedTotal(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  let total = getTotal(lifter, divisionId, meet, forecasted);
  if (isLbsMeet(meet)) {
    total = toKg(total);
  }

  return total * wilksCoef(lifter, meet);
};

export const getAgeAdjustedTotal = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
) {
  let total = getTotal(lifter, divisionId, meet, forecasted);
  if (isLbsMeet(meet)) {
    total = toKg(total);
  }

  return total * ageCoef(lifter, meet);
};

export const getBodyWeightAndAgeAdjustedTotal = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getBodyWeightAndAgeAdjustedTotal(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getBodyWeightAndAgeAdjustedTotal(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  let total = getTotal(lifter, divisionId, meet, forecasted);
  if (isLbsMeet(meet)) {
    total = toKg(total);
  }

  return total * wilksCoef(lifter, meet) * ageCoef(lifter, meet);
};

export const getIpfPoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getIpfPoints(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getIpfPoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  let total = getTotal(lifter, divisionId, meet, forecasted);
  if (isLbsMeet(meet)) {
    total = toKg(total);
  }

  const division = getDivisionDoc(divisionId, meet);

  return ipfFormula(total, division, lifter, meet);
};

export const getIpfAndAgePoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getIpfAndAgePoints(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getIpfAndAgePoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  let total = getTotal(lifter, divisionId, meet, forecasted);
  if (isLbsMeet(meet)) {
    total = toKg(total);
  }

  const division = getDivisionDoc(divisionId, meet);

  return ipfFormula(total, division, lifter, meet) * ageCoef(lifter, meet);
};

export const getDotsPoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getDotsPoints(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getDotsPoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  let total = getTotal(lifter, divisionId, meet, forecasted);
  if (isLbsMeet(meet)) {
    total = toKg(total);
  }

  return dotsFormula(total, lifter, meet);
};

export const getDotsAndAgePoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getDotsAndAgePoints(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getDotsAndAgePoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  let total = getTotal(lifter, divisionId, meet, forecasted);
  if (isLbsMeet(meet)) {
    total = toKg(total);
  }

  return dotsFormula(total, lifter, meet) * ageCoef(lifter, meet);
};

export const getPercentOfRecordPoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getPercentOfRecordPoints(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getPercentOfRecordPoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  let total = getTotal(lifter, divisionId, meet, forecasted);
  if (isLbsMeet(meet)) {
    total = toKg(total);
  }
  const division = getDivisionDoc(divisionId, meet);

  return percentOfRecord(total, division, lifter, meet);
};

export const getKPoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getKPoints({ ...lifter, gender: "MALE" }, divisionId, meet, forecasted) +
        getKPoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  let total = getTotal(lifter, divisionId, meet, forecasted);
  if (isLbsMeet(meet)) {
    total = toKg(total);
  }

  const division = getDivisionDoc(divisionId, meet);

  return kPointsFormula(total, division, lifter, meet);
};

export const getKAndAgePoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getKAndAgePoints(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getKAndAgePoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  let total = getTotal(lifter, divisionId, meet, forecasted);
  if (isLbsMeet(meet)) {
    total = toKg(total);
  }

  const division = getDivisionDoc(divisionId, meet);

  return kPointsFormula(total, division, lifter, meet) * ageCoef(lifter, meet);
};

export const getSchwartzMalonePoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getSchwartzMalonePoints(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getSchwartzMalonePoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  const total = getTotal(lifter, divisionId, meet, forecasted);
  return total * schwartzMaloneCoef(lifter, meet);
};

export const getSchwartzMaloneAndAgePoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getSchwartzMaloneAndAgePoints(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getSchwartzMaloneAndAgePoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  const total = getTotal(lifter, divisionId, meet, forecasted);
  return total * schwartzMaloneCoef(lifter, meet) * ageCoef(lifter, meet);
};

export const getAhPoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getAhPoints(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getAhPoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  let total = getTotal(lifter, divisionId, meet, forecasted);
  if (isLbsMeet(meet)) {
    total = toKg(total);
  }

  return ahPoints(total, lifter, meet);
};

export const getParaDotsPoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getParaDotsPoints(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getParaDotsPoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  let total = getTotal(lifter, divisionId, meet, forecasted);
  if (isLbsMeet(meet)) {
    total = toKg(total);
  }

  return paraDotsFormula(total, lifter, meet);
};

export const getGlossbrennerPoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getGlossbrennerPoints(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getGlossbrennerPoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  const total = getTotal(lifter, divisionId, meet, forecasted);
  return total * glossbrennerCoef(total, lifter, meet);
};

export const getGlossbrennerAndAgePoints = function (
  lifter: Lifter,
  divisionId: string,
  meet: Meet,
  forecasted?: boolean
): number {
  if (lifter.gender === "MX") {
    return (
      (getGlossbrennerAndAgePoints(
        { ...lifter, gender: "MALE" },
        divisionId,
        meet,
        forecasted
      ) +
        getGlossbrennerAndAgePoints(
          { ...lifter, gender: "FEMALE" },
          divisionId,
          meet,
          forecasted
        )) /
      2
    );
  }
  const total = getTotal(lifter, divisionId, meet, forecasted);
  return total * glossbrennerCoef(total, lifter, meet) * ageCoef(lifter, meet);
};

export const getScore = function (
  lifter: Lifter,
  division: Division,
  meet: Meet,
  forecasted?: boolean
) {
  if (division) {
    if (division.scoreBy === "BODY_WEIGHT_POINTS") {
      return getBodyWeightAdjustedTotal(lifter, division._id, meet, forecasted);
    } else if (division.scoreBy === "AGE_POINTS") {
      return getAgeAdjustedTotal(lifter, division._id, meet, forecasted);
    } else if (division.scoreBy === "BODY_WEIGHT_AND_AGE_POINTS") {
      return getBodyWeightAndAgeAdjustedTotal(
        lifter,
        division._id,
        meet,
        forecasted
      );
    } else if (division.scoreBy === "IPF_POINTS") {
      return getIpfPoints(lifter, division._id, meet, forecasted);
    } else if (division.scoreBy === "IPF_AND_AGE_POINTS") {
      return getIpfAndAgePoints(lifter, division._id, meet, forecasted);
    } else if (division.scoreBy === "DOTS_POINTS") {
      return getDotsPoints(lifter, division._id, meet, forecasted);
    } else if (division.scoreBy === "DOTS_AND_AGE_POINTS") {
      return getDotsAndAgePoints(lifter, division._id, meet, forecasted);
    } else if (division.scoreBy === "SCHWARTZ_MALONE") {
      return getSchwartzMalonePoints(lifter, division._id, meet, forecasted);
    } else if (division.scoreBy === "SCHWARTZ_MALONE_AND_AGE_POINTS") {
      return getSchwartzMaloneAndAgePoints(
        lifter,
        division._id,
        meet,
        forecasted
      );
    } else if (division.scoreBy === "AH_POINTS") {
      return getAhPoints(lifter, division._id, meet, forecasted);
    } else if (division.scoreBy === "PARA_DOTS_POINTS") {
      return getParaDotsPoints(lifter, division._id, meet, forecasted);
    } else if (division.scoreBy === "GLOSSBRENNER_POINTS") {
      return getGlossbrennerPoints(lifter, division._id, meet, forecasted);
    } else if (division.scoreBy === "GLOSSBRENNER_AND_AGE_POINTS") {
      return getGlossbrennerAndAgePoints(
        lifter,
        division._id,
        meet,
        forecasted
      );
    } else if (division.scoreBy === "K_POINTS") {
      return getKPoints(lifter, division._id, meet, forecasted);
    } else if (division.scoreBy === "PERCENT_OF_RECORD") {
      return getPercentOfRecordPoints(lifter, division._id, meet, forecasted);
    } else {
      return getTotal(lifter, division._id, meet, forecasted);
    }
  }

  return "";
};

export const getRealAge = function (lifter: Lifter, meet: Meet) {
  let age = null;
  if (meet.date && lifter.birthDate) {
    const meetDate = parseDateString({ dateString: meet.date, meet });
    const lifterBirthDate = parseDateString({
      dateString: lifter.birthDate,
      meet,
    });
    if (meetDate && lifterBirthDate) {
      age = differenceInYears(meetDate, lifterBirthDate);
    }
  }
  return age;
};

export const getYearAge = function (lifter: Lifter, meet: Meet) {
  let age = null;
  if (meet.date && lifter.birthDate) {
    const meetDate = parseDateString({ dateString: meet.date, meet });
    const lifterBirthDateOriginal = parseDateString({
      dateString: lifter.birthDate,
      meet,
    });
    if (lifterBirthDateOriginal && meetDate) {
      const lifterBirthDate = startOfYear(lifterBirthDateOriginal);
      age = differenceInYears(meetDate, lifterBirthDate);
    }
  }

  return age;
};

export const getLiftersInDivisionAndWeightClass = ({
  meet,
  division,
  weightClass,
}: {
  meet: Meet;
  division: Division;
  weightClass: WeightClass;
}) => {
  return filter(meet.lifters, (lifter) => {
    return !!find(lifter.divisions, (lifterDivision) => {
      if (lifterDivision.divisionId !== division._id) {
        return false;
      }
      const weightClassDoc = getLifterAwardsWeightClassDoc(
        meet,
        lifter,
        lifterDivision
      );
      return get(weightClassDoc, "_id") === weightClass._id;
    });
  });
};

export const sortLiftersByPlace = ({
  lifters,
  division,
  meet,
  forecasted,
}: {
  lifters: Lifter[];
  division: Division;
  meet: Meet;
  forecasted?: boolean;
}) => {
  return orderBy(
    lifters,
    [
      (lifter) => getScore(lifter, division, meet, forecasted),
      (lifter) => {
        if (division.scoreBy === "TOTAL") {
          return lifter.bodyWeight || 0;
        }

        return 0;
      },
      (lifter) => getLastSuccessfulLiftTimestamp(lifter) || "",
    ],
    ["desc", "asc", "asc"]
  );
};

export const getPlace = function (
  lifter: Lifter,
  lifterDivision: LifterDivision,
  meet: Meet,
  forecasted?: boolean
) {
  const lifterDivisionDoc = getDivisionDoc(lifterDivision.divisionId, meet);
  const lifterWeightClass = getLifterAwardsWeightClassDoc(
    meet,
    lifter,
    lifterDivision
  );

  if (!lifterDivisionDoc || !lifterWeightClass) {
    return null;
  }

  const lifterTotal = getTotal(lifter, lifterDivisionDoc._id, meet, forecasted);
  if (!lifterTotal) {
    return null;
  }

  const divisionLifters = getLiftersInDivisionAndWeightClass({
    meet,
    division: lifterDivisionDoc,
    weightClass: lifterWeightClass as WeightClass,
  });

  const sortedLifters = sortLiftersByPlace({
    lifters: divisionLifters,
    meet,
    division: lifterDivisionDoc,
    forecasted,
  });

  const lifterPlace = findIndex(sortedLifters, { _id: lifter._id }) + 1;
  return lifterPlace;
};
