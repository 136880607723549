import React from "react";
import DropdownMenu from "components/dropDownMenu/DropdownMenu";
import { useParams } from "react-router-dom";
import get from "lodash/get";
import noop from "lodash/noop";

import { unitedStatesMeet, canadaMeet } from "util/meetHelper";

import {
  updateAttributeOnDocument,
  updateAttributesOnDocument,
} from "util/pouchActions";
import { Attempt, Meet } from "types";
import { formatEligibleRecord } from "util/records";
import { useRecordAttempts } from "util/useRecordAttempts";

const Menu = ({
  meet,
  attempt,
  isCurrentAttempt,
}: {
  meet: Meet;
  attempt: Attempt;
  isCurrentAttempt?: boolean;
}) => {
  let { platformId } = useParams<{
    platformId: string;
  }>();

  const setAsCurrentAttempt = () => {
    updateAttributeOnDocument(
      meet._id,
      platformId,
      "currentAttemptId",
      attempt._id
    );
  };

  const clearResult = () => {
    const changes = {
      result: null,
      decisions: null,
    };
    updateAttributesOnDocument(meet._id, attempt._id, changes);
  };

  const markAttemptGood = () => {
    updateAttributeOnDocument(meet._id, attempt._id, "result", "good");
  };

  const markAttemptBad = () => {
    updateAttributeOnDocument(meet._id, attempt._id, "result", "bad");
  };

  const toggleRecordAttempt = (type: any) => {
    updateAttributeOnDocument(meet._id, attempt._id, type, !get(attempt, type));
  };

  const moveToEndOfRound = () => {
    updateAttributeOnDocument(meet._id, attempt._id, "endOfRound", true);
  };

  const moveToNormalPosition = () => {
    updateAttributeOnDocument(meet._id, attempt._id, "endOfRound", false);
  };

  const allRecords = useRecordAttempts();

  const recordsForAttempt = allRecords[attempt._id];

  if (!attempt.weight && attempt.weight !== 0 && !attempt.result) {
    return null;
  }

  const items = [];

  if (
    !isCurrentAttempt &&
    !attempt.result &&
    (attempt.weight || attempt.weight === 0)
  ) {
    items.push({
      label: "Set as current attempt",
      onClick: setAsCurrentAttempt,
    });
  }

  if (attempt.result) {
    items.push({
      label: "Clear result",
      onClick: clearResult,
    });
  }

  if (
    attempt.weight !== 0 &&
    attempt.weight &&
    (!attempt.result || attempt.result === "bad")
  ) {
    items.push({
      label: "Mark as good attempt",
      onClick: markAttemptGood,
    });
  }

  if (
    (attempt.weight || attempt.weight === 0) &&
    (!attempt.result || attempt.result === "good")
  ) {
    items.push({
      label: "Mark as bad attempt",
      onClick: markAttemptBad,
    });
  }

  if (
    (attempt.weight || attempt.weight === 0) &&
    !attempt.result &&
    !attempt.endOfRound
  ) {
    items.push({
      label: "Move to end of round",
      onClick: moveToEndOfRound,
    });
  }

  if (
    (attempt.weight || attempt.weight === 0) &&
    !attempt.result &&
    attempt.endOfRound
  ) {
    items.push({
      label: "Move to normal postition in round",
      onClick: moveToNormalPosition,
    });
  }

  items.push({
    label: "Manual Records",
  });

  if (unitedStatesMeet(meet)) {
    items.push({
      label: "State Record Attempt",
      onClick: () => toggleRecordAttempt("stateRecord"),
      checked: !!attempt.stateRecord,
    });
  }

  if (unitedStatesMeet(meet) && meet.federation !== "WRPF") {
    items.push({
      label: "American Record Attempt",
      onClick: () => toggleRecordAttempt("americanRecord"),
      checked: !!attempt.americanRecord,
    });
  }

  if (canadaMeet(meet) || meet.federation === "IPF") {
    items.push({
      label: "Provincial Record Attempt",
      onClick: () => toggleRecordAttempt("provincialRecord"),
      checked: !!attempt.provincialRecord,
    });
  }

  if (canadaMeet(meet)) {
    items.push({
      label: "Canadian Record Attempt",
      onClick: () => toggleRecordAttempt("canadianRecord"),
      checked: !!attempt.canadianRecord,
    });
  }

  items.push({
    label: "Regional Record Attempt",
    onClick: () => toggleRecordAttempt("regionalRecord"),
    checked: !!attempt.regionalRecord,
  });

  items.push({
    label: "National Record Attempt",
    onClick: () => toggleRecordAttempt("nationalRecord"),
    checked: !!attempt.nationalRecord,
  });

  items.push({
    label: "World Record Attempt",
    onClick: () => toggleRecordAttempt("worldRecord"),
    checked: !!attempt.worldRecord,
  });

  if (meet.records) {
    items.push({
      label: "Automatic Records",
    });
    if (recordsForAttempt) {
      recordsForAttempt.forEach((record) => {
        items.push({
          label: formatEligibleRecord({ record }),
          onClick: noop,
        });
      });
    } else {
      items.push({
        label: "None",
        onClick: noop,
      });
    }
  }

  return <DropdownMenu items={items} />;
};

export default Menu;
