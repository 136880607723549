import React from "react";
import { EligibleRecord, Meet } from "types";
import {
  formatEligibleRecord,
  getEligibleRecordsForLifter,
  getRecordWeightForEligibleRecord,
} from "util/records";
import map from "lodash/map";
import { useMeet } from "util/useMeet";
import { useLifter } from "util/useLifter";

const RecordsForType = ({
  meet,
  eligibleRecord,
}: {
  meet: Meet;
  eligibleRecord: EligibleRecord;
}) => {
  if (!meet.records) {
    return null;
  }

  // TODO: maybe pass in a flag here to not check for new records in this meet.
  // or maybe show both?
  const recordWeight = getRecordWeightForEligibleRecord({
    meet,
    eligibleRecord,
  });

  if (!recordWeight) {
    return null;
  }

  return (
    <div className="record-title">
      {formatEligibleRecord({ record: { ...eligibleRecord, recordWeight } })}
    </div>
  );
};

const EligibleRecords = () => {
  const meet = useMeet();
  const lifter = useLifter();

  const eligibleRecords = getEligibleRecordsForLifter({ lifter, meet });

  return (
    <div className="eligible-records">
      <div>
        Possible eligible records. Only records meet director has loaded will
        show up here. Check official federation records database to confirm.
      </div>
      <br />
      {map(eligibleRecords, (eligibleRecord, index) => {
        return (
          <RecordsForType
            key={index}
            meet={meet}
            eligibleRecord={eligibleRecord}
          />
        );
      })}
    </div>
  );
};

export default EligibleRecords;
