import React from "react";
import Select from "components/select/Select";
import FixedHeightWrapper from "app/meets/FixedHeightWrapper";
import LiftersTable from "./LiftersTable";
import FiltersIcon from "icons/FiltersIcon";
import Drawer from "components/drawer/Drawer";
import GenerateLotNumbersModal from "./GenerateLotNumbersModal";
import { exportLifters } from "util/exportData";
import {
  getTeamOptions,
  getPlatformOptions,
  getSessionOptions,
  getFlightOptions,
  getAwardDivisionOptions,
  getGenderOptions,
} from "util/options";
import "./LiftersIndex.scss";
import { useMediaSize } from "util/useMediaSize";
import { useMeet } from "util/useMeet";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import some from "lodash/some";

const initialFilters: {
  name: string;
  team: string | null;
  gender: string | null;
  platformId: string | null;
  session: number | null;
  flight: string | null;
  divisionId: string | null;
} = {
  name: "",
  team: null,
  gender: null,
  platformId: null,
  session: null,
  flight: null,
  divisionId: null,
};

const LiftersIndex = ({
  onFinishBulkCreate,
}: {
  onFinishBulkCreate: () => void;
}) => {
  const media = useMediaSize();
  const meet = useMeet();
  const [showGenerateLotNumbersModal, setShowGenerateLotNumbersModal] =
    React.useState(false);
  const [filters, setFilters] = React.useState(initialFilters);

  const openGenerateLotNumbersModal = () => {
    setShowGenerateLotNumbersModal(true);
  };

  const closeGenerateLotNumbersModal = () => {
    setShowGenerateLotNumbersModal(false);
  };

  const clearFilters = () => {
    setFilters(initialFilters);
  };

  const onChangeNameFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prev) => ({ ...prev, name: e.target.value }));
  };

  const onChangeSelectFilter = (
    name: keyof typeof initialFilters,
    value: string | number | undefined | null
  ) => {
    setFilters({ ...filters, [name]: value });
  };

  const filteredLifters = filter(meet.lifters, (l) => {
    const nameSearchMatch = includes(toLower(l.name), toLower(filters.name));
    const teamMatch = !filters.team || l.team === filters.team;
    const platformIdMatch =
      !filters.platformId || l.platformId === filters.platformId;
    const sessionMatch = !filters.session || l.session === filters.session;
    const flightMatch = !filters.flight || l.flight === filters.flight;
    const genderMatch = !filters.gender || l.gender === filters.gender;
    const divisionIdMatch =
      !filters.divisionId ||
      some(l.divisions, (d) => d.divisionId === filters.divisionId);

    // don't show lifters without an id. Either data hasn't fully loaded or something went wrong.
    const validId = l._id;

    return !!(
      validId &&
      nameSearchMatch &&
      teamMatch &&
      platformIdMatch &&
      sessionMatch &&
      flightMatch &&
      genderMatch &&
      divisionIdMatch
    );
  });

  const activeFilters = !!(
    filters.name ||
    filters.team ||
    filters.platformId ||
    filters.session ||
    filters.flight ||
    filters.gender ||
    filters.divisionId
  );

  return (
    <FixedHeightWrapper>
      <div className="lifters-index">
        <div className="util-row-wrapper">
          <div className="util-row">
            <button onClick={() => exportLifters(meet, meet.lifters)}>
              Export Lifters
            </button>
            <button
              className="generate-lot-numbers-button"
              onClick={openGenerateLotNumbersModal}
            >
              Generate Lot Numbers
            </button>
            <Drawer trigger={<FiltersIcon active={activeFilters} />}>
              <div className="title">Lifter Filters</div>
              <button className="clear-button" onClick={clearFilters}>
                Clear All
              </button>
              <div className="row">
                Name:
                <input
                  type="text"
                  onChange={onChangeNameFilter}
                  value={filters.name}
                />
              </div>
              <div className="row">
                Team:
                <Select
                  name="team"
                  value={filters.team}
                  options={getTeamOptions({}, meet)}
                  onChange={(value) => onChangeSelectFilter("team", value)}
                />
              </div>
              <div className="row">
                Platform:
                <Select
                  name="platformId"
                  value={filters.platformId}
                  options={getPlatformOptions({}, meet)}
                  onChange={(value) =>
                    onChangeSelectFilter("platformId", value)
                  }
                />
              </div>
              <div className="row">
                Session:
                <Select
                  name="session"
                  value={filters.session}
                  options={getSessionOptions()}
                  onChange={(value) => onChangeSelectFilter("session", value)}
                />
              </div>
              <div className="row">
                Flight:
                <Select
                  name="flight"
                  value={filters.flight}
                  options={getFlightOptions()}
                  onChange={(value) => onChangeSelectFilter("flight", value)}
                />
              </div>
              <div className="row">
                Gender:
                <Select
                  name="gender"
                  value={filters.gender}
                  options={getGenderOptions({}, meet)}
                  onChange={(value) => onChangeSelectFilter("gender", value)}
                />
              </div>
              <div className="row">
                Awards Division:
                <Select
                  name="divisionId"
                  value={filters.divisionId}
                  options={getAwardDivisionOptions({}, meet, undefined)}
                  onChange={(value) =>
                    onChangeSelectFilter("divisionId", value)
                  }
                />
              </div>
            </Drawer>
          </div>
        </div>
        <div className="table-wrapper">
          <LiftersTable meet={meet} lifters={filteredLifters} media={media} />
        </div>
        <GenerateLotNumbersModal
          meet={meet}
          isOpen={showGenerateLotNumbersModal}
          onRequestClose={closeGenerateLotNumbersModal}
          onFinishBulkCreate={onFinishBulkCreate}
        />
      </div>
    </FixedHeightWrapper>
  );
};

export default LiftersIndex;
