import React from "react";
import Modal from "react-modal";
import { errorReporter } from "util/errorReporter";
import { fetchWrapper } from "util/api";

import "./PasswordModal.scss";
import { Meet } from "types";
import get from "lodash/get";

type PasswordModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onRequestClose: () => void;
  meet: Meet;
  meetId: string;
  mode: "set" | "get";
  onOk: (
    password: string,
    confirmationToken: string,
    email: string | undefined
  ) => Promise<void>;
};
type PasswordModalState = {
  password: string;
  passwordConfirmation: string;
  error: string;
  step:
    | "initial"
    | "confirmNewToken"
    | "confirmOldToken"
    | "setPassword"
    | "forgotPassword"
    | "resetPassword"
    | "resetComplete";
  confirmationToken: string;
  loading: boolean;
};

const initialState: PasswordModalState = {
  password: "",
  passwordConfirmation: "",
  error: "",
  step: "initial",
  confirmationToken: "",
  loading: false,
};

const PasswordModal = ({
  meet,
  meetId,
  onCancel,
  onRequestClose,
  mode,
  isOpen,
  onOk,
}: PasswordModalProps) => {
  const [state, setState] = React.useState<PasswordModalState>(initialState);

  const onChange = (
    value: string,
    name: "password" | "passwordConfirmation" | "confirmationToken"
  ) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onError = (errorMessage: string) => {
    onCancel();
    onCloseAndResetForm();
    alert(errorMessage);
  };

  const onCancelInternal = () => {
    onCancel();
    onCloseAndResetForm();
  };

  const onCloseAndResetForm = () => {
    setState(initialState);
    onRequestClose();
  };

  const onClickSendEmailToken = async (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));
    // send token email
    // /api/meets/:meet_id/email_validation
    const emailAddress = get(meet, "contactEmail");
    try {
      const response = await fetchWrapper(
        `/api/meets/${meetId}/email_validation`,
        "POST",
        {
          email_address: emailAddress,
        }
      );
      if (response.ok) {
        setState((prev) => ({
          ...prev,
          step: "confirmNewToken",
          loading: false,
        }));
      } else {
        onError(response.error);
      }
    } catch (error: any) {
      errorReporter({ message: "Email Token Failure", error });
      onError("Something went wrong sending email token");
    }
  };

  const onClickAlreadyHaveEmailToken = (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, step: "confirmOldToken" }));
  };

  const onClickEnterToken = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, step: "setPassword" }));
  };

  const onClickForgotPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, step: "forgotPassword" }));
  };

  const onClickGoToReset = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, step: "resetPassword" }));
  };

  const onClickSendEmailTokenForReset = async (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const response = await fetchWrapper(
        `/api/meets/${meetId}/send_reset_token`,
        "POST"
      );
      if (response.ok) {
        setState((prev) => ({
          ...prev,
          step: "resetPassword",
          loading: false,
        }));
      } else {
        onError(response.error);
      }
    } catch (error: any) {
      errorReporter({ message: "Send Reset Token Failure", error });
      onError("Something went wrong sending password reset token");
    }
  };

  const onClickResetPassword = async (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const response = await fetchWrapper(
        `/api/meets/${meetId}/reset_password`,
        "POST",
        {
          confirmation_token: state.confirmationToken,
          password: state.password,
        }
      );
      if (response.ok) {
        setState((prev) => ({
          ...prev,
          step: "resetComplete",
          loading: false,
        }));
      } else {
        onError(response.error);
      }
    } catch (error: any) {
      errorReporter({ message: "Password Reset Failure", error });
      onError("Something went wrong resetting password");
    }
  };

  const onClickContinue = async (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!state.password) {
      setState((prev) => ({ ...prev, error: "Must enter a password." }));
      return;
    }

    if (
      (mode === "set" && state.password === state.passwordConfirmation) ||
      mode === "get"
    ) {
      setState((prev) => ({ ...prev, loading: true }));
      await onOk(
        state.password,
        state.confirmationToken,
        get(meet, "contactEmail")
      );
      onCloseAndResetForm();
    } else if (mode === "set") {
      setState((prev) => ({ ...prev, error: "Passwords must match." }));
    }
  };

  const validated = get(meet, "validated");

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onCancelInternal}
      shouldCloseOnOverlayClick={false}
      className={{
        base: "password-modal",
        afterOpen: "password-modal-after-open",
        beforeClose: "password-modal-before-close",
      }}
      overlayClassName={{
        base: "password-modal-overlay",
        afterOpen: "password-modal-overlay-after-open",
        beforeClose: "password-modal-overlay-before-close",
      }}
      contentLabel="password modal"
    >
      <div className="content">
        {state.loading && <div className="login-loading-overlay"></div>}
        {state.loading && <div className="login-loading-text">Loading...</div>}
        {mode === "set" && (
          <div>
            <div>
              Using LiftingCast in offline mode is free. Using cloud services
              cost $1.25 per lifter. To be paid after the meet is finished.
              <br />
              <br />
            </div>
            {state.step === "initial" && (
              <div>
                <div className="title">
                  You must confirm your email address to continue.
                </div>
                <div className="title">Confirmation token will be sent to:</div>
                <div className="title">{get(meet, "contactEmail")}</div>
                <div>
                  If you have previously confirmed this email address for
                  another event you can use the previous token.
                </div>
                <br />
                <br />
                <form>
                  <div className="button-row">
                    <input
                      disabled={state.loading}
                      className="password-cancel-button"
                      type="button"
                      onClick={onCancelInternal}
                      value="Cancel"
                    />
                    <div>
                      <input
                        disabled={state.loading}
                        style={{ marginBottom: 20, minWidth: 200 }}
                        type="button"
                        onClick={onClickAlreadyHaveEmailToken}
                        value="I Already Have A Token"
                      />
                      <input
                        disabled={state.loading}
                        style={{ minWidth: 200 }}
                        type="submit"
                        onClick={onClickSendEmailToken}
                        value="Send New Confirmation Token"
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
            {(state.step === "confirmNewToken" ||
              state.step === "confirmOldToken") && (
              <div>
                {state.step === "confirmNewToken" && (
                  <div className="title">
                    Email validation token has been sent to{" "}
                    {get(meet, "contactEmail")}. If you did not receive the
                    email check your spam folder.
                  </div>
                )}
                {state.step === "confirmOldToken" && (
                  <div className="title">
                    Enter email validation token previously sent to{" "}
                    {get(meet, "contactEmail")}.
                  </div>
                )}
                <br />
                <div>
                  <form>
                    <input
                      autoFocus
                      type="text"
                      placeholder="confirmation token"
                      name="confirmation-token"
                      onChange={(e) =>
                        onChange(e.target.value, "confirmationToken")
                      }
                    />
                    <br />
                    <br />
                    <div className="button-row">
                      <input
                        disabled={state.loading}
                        className="password-cancel-button"
                        type="button"
                        onClick={onCancelInternal}
                        value="Cancel"
                      />
                      <input
                        disabled={state.loading}
                        type="submit"
                        onClick={onClickEnterToken}
                        value="Next"
                      />
                    </div>
                  </form>
                </div>
              </div>
            )}
            {state.step === "setPassword" && (
              <div>
                <div className="title">Set Shareable Password</div>
                <div className="title">
                  This password can be used to log into this meet from other
                  computers. The password you set is only for this event and
                  should be unique to this event.
                </div>
                <form>
                  <input
                    type="text"
                    name="username"
                    defaultValue={meetId}
                    style={{ display: "none" }}
                  />
                  <input
                    autoFocus
                    type="password"
                    placeholder="password"
                    name="current-password"
                    onChange={(e) => onChange(e.target.value, "password")}
                  />
                  <input
                    type="password"
                    className="confirm-password"
                    placeholder="confirm password"
                    name="confirm-password"
                    onChange={(e) =>
                      onChange(e.target.value, "passwordConfirmation")
                    }
                  />
                  <div className="error">
                    {state.error && <div>{state.error}</div>}
                  </div>
                  <div className="button-row">
                    <input
                      disabled={state.loading}
                      className="password-cancel-button"
                      type="button"
                      onClick={onCancelInternal}
                      value="Cancel"
                    />
                    <input
                      disabled={state.loading}
                      type="submit"
                      onClick={onClickContinue}
                      value={mode === "set" ? "Agree To Pay" : "Continue"}
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        )}

        {mode === "get" && (
          <div>
            {state.step === "resetComplete" && (
              <div style={{ marginBottom: 20 }}>Password Reset Complete</div>
            )}
            <div className="title">Meet Director Login</div>
            {(state.step === "initial" || state.step === "resetComplete") && (
              <div>
                <form>
                  <input
                    type="text"
                    name="username"
                    defaultValue={meetId}
                    style={{ display: "none" }}
                  />
                  <input
                    autoFocus
                    type="password"
                    placeholder="password"
                    name="current-password"
                    onChange={(e) => onChange(e.target.value, "password")}
                  />
                  <div className="error">
                    {state.error && <div>{state.error}</div>}
                  </div>
                  <div className="button-row">
                    <input
                      disabled={state.loading}
                      className="password-cancel-button"
                      type="button"
                      onClick={onCancelInternal}
                      value="Cancel"
                    />
                    <input
                      disabled={state.loading}
                      type="submit"
                      onClick={onClickContinue}
                      value="Continue"
                    />
                  </div>
                </form>
                {validated && (
                  <div className="forgot-password-button">
                    <button
                      disabled={state.loading}
                      onClick={onClickForgotPassword}
                    >
                      I forgot my password
                    </button>
                  </div>
                )}
              </div>
            )}
            {state.step === "forgotPassword" && (
              <div>
                <div style={{ marginBottom: 20 }}>
                  Do you have the validation token that was sent when you
                  confirmed your email address?
                </div>
                <div className="button-row">
                  <input
                    className="password-cancel-button"
                    type="button"
                    onClick={onCancelInternal}
                    value="Cancel"
                  />
                  <div>
                    <input
                      disabled={state.loading}
                      style={{ marginBottom: 20, minWidth: 200 }}
                      type="button"
                      onClick={onClickGoToReset}
                      value="I Already Have A Token"
                    />
                    <input
                      disabled={state.loading}
                      style={{ minWidth: 200 }}
                      type="submit"
                      onClick={onClickSendEmailTokenForReset}
                      value="Send New Confirmation Token"
                    />
                  </div>
                </div>
              </div>
            )}
            {state.step === "resetPassword" && (
              <form>
                <input
                  type="text"
                  name="username"
                  defaultValue={meetId}
                  style={{ display: "none" }}
                />
                <div>
                  Enter confirmation token sent to you when you validated your
                  email.
                </div>
                <input
                  autoFocus
                  type="text"
                  placeholder="confirmation token"
                  name="confirmation-token"
                  onChange={(e) =>
                    onChange(e.target.value, "confirmationToken")
                  }
                  style={{ marginBottom: 20, marginTop: 20 }}
                />
                <input
                  type="password"
                  placeholder="new password"
                  name="current-password"
                  onChange={(e) => onChange(e.target.value, "password")}
                />
                <input
                  type="password"
                  className="confirm-password"
                  placeholder="confirm new password"
                  name="confirm-password"
                  onChange={(e) =>
                    onChange(e.target.value, "passwordConfirmation")
                  }
                />
                <div className="error">
                  {state.error && <div>{state.error}</div>}
                </div>
                <div className="button-row">
                  <input
                    disabled={state.loading}
                    className="password-cancel-button"
                    type="button"
                    onClick={onCancelInternal}
                    value="Cancel"
                  />
                  <input
                    disabled={state.loading}
                    type="submit"
                    onClick={onClickResetPassword}
                    value="Reset Password"
                  />
                </div>
              </form>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PasswordModal;
