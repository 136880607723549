import each from "lodash/each";
import toLower from "lodash/toLower";

import { getAttemptDisplay } from "../exportData";
import {
  getRealAge,
  getTotal,
  getDotsPoints,
  getDotsAndAgePoints,
} from "../lifterHelper";
import { isLbsMeet } from "../meetHelper";
import { toKg } from "../conversions";
import { FederationDivisionsConfig, Meet } from "types";

// prettier-ignore
const uspaWeightClasses = {
  'MALE': [
    {name: '52',   lbsName: '114.64lbs (52kg)',    maxWeight: 52,   lbsMaxWeight: 114.64},
    {name: '56',   lbsName: '123.46lbs (56kg)',    maxWeight: 56,   lbsMaxWeight: 123.46},
    {name: '60',   lbsName: '132.28lbs (60kg)',    maxWeight: 60,   lbsMaxWeight: 132.28},
    {name: '67.5', lbsName: '148.81lbs (67.5kg)',  maxWeight: 67.5, lbsMaxWeight: 148.81},
    {name: '75',   lbsName: '165.35lbs (75kg)',    maxWeight: 75,   lbsMaxWeight: 165.35},
    {name: '82.5', lbsName: '181.88lbs (82.5kg)',  maxWeight: 82.5, lbsMaxWeight: 181.88},
    {name: '90',   lbsName: '198.42lbs (90kg)',    maxWeight: 90,   lbsMaxWeight: 198.42},
    {name: '100',  lbsName: '220.46lbs (100kg)',   maxWeight: 100,  lbsMaxWeight: 220.46},
    {name: '110',  lbsName: '242.51lbs (110kg)',   maxWeight: 110,  lbsMaxWeight: 242.51},
    {name: '125',  lbsName: '275.58lbs (125kg)',   maxWeight: 125,  lbsMaxWeight: 275.58},
    {name: '140',  lbsName: '308.65lbs (140kg)',   maxWeight: 140,  lbsMaxWeight: 308.65},
    {name: '140+', lbsName: '308.65lbs+ (140kg+)', maxWeight: 9999, lbsMaxWeight: 9999}
  ],
  'FEMALE': [
    {name: '44',   lbsName: '97.00lbs (44kg)',     maxWeight: 44,   lbsMaxWeight: 97.00},
    {name: '48',   lbsName: '105.82lbs (48kg)',    maxWeight: 48,   lbsMaxWeight: 105.82},
    {name: '52',   lbsName: '114.64lbs (52kg)',    maxWeight: 52,   lbsMaxWeight: 114.64},
    {name: '56',   lbsName: '123.46lbs (56kg)',    maxWeight: 56,   lbsMaxWeight: 123.46},
    {name: '60',   lbsName: '132.28lbs (60kg)',    maxWeight: 60,   lbsMaxWeight: 132.28},
    {name: '67.5', lbsName: '148.81lbs (67.5kg)',  maxWeight: 67.5, lbsMaxWeight: 148.81},
    {name: '75',   lbsName: '165.35lbs (75kg)',    maxWeight: 75,   lbsMaxWeight: 165.35},
    {name: '82.5', lbsName: '181.88lbs (82.5kg)',  maxWeight: 82.5, lbsMaxWeight: 181.88},
    {name: '90',   lbsName: '198.42lbs (90kg)',    maxWeight: 90,   lbsMaxWeight: 198.42},
    {name: '100',  lbsName: '220.46lbs (100kg)',   maxWeight: 100,  lbsMaxWeight: 220.46},
    {name: '110',  lbsName: '242.51lbs (110kg)',   maxWeight: 110,  lbsMaxWeight: 242.51},
    {name: '110+', lbsName: '242.51lbs+ (110kg+)', maxWeight: 9999, lbsMaxWeight: 9999}
  ]
};

// prettier-ignore
export const uspaBaseDivisions: FederationDivisionsConfig = [
  {name: "Tested Junior (13-15)",     code: 'J1',  low: 14,  high: 15,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Junior (16-17)",     code: 'J2',  low: 16,  high: 17,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Junior (18-19)",     code: 'J3',  low: 18,  high: 19,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Junior (20-23)",     code: 'J4',  low: 20,  high: 23,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Open",               code: 'O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Sub-Master (35-39)", code: 'SM',  low: 35,  high: 39,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Master (40-44)",     code: 'M1',  low: 40,  high: 44,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Master (45-49)",     code: 'M2',  low: 45,  high: 49,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Master (50-54)",     code: 'M3',  low: 50,  high: 54,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Master (55-59)",     code: 'M4',  low: 55,  high: 59,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Master (60-64)",     code: 'M5',  low: 60,  high: 64,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Master (65-69)",     code: 'M6',  low: 65,  high: 69,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Master (70-74)",     code: 'M7',  low: 70,  high: 74,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Master (75-79)",     code: 'M8',  low: 75,  high: 79,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Tested Master (80+)",       code: 'M9',  low: 80,  high: 999,  default: true,  records: true,  weightClasses: uspaWeightClasses},

  {name: "Untested Junior (13-15)",     code: 'J1',  low: 14,  high: 15,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Junior (16-17)",     code: 'J2',  low: 16,  high: 17,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Junior (18-19)",     code: 'J3',  low: 18,  high: 19,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Junior (20-23)",     code: 'J4',  low: 20,  high: 23,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Open",               code: 'O',   low: 24,  high: 39,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Sub-Master (35-39)", code: 'SM',  low: 35,  high: 39,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Master (40-44)",     code: 'M1',  low: 40,  high: 44,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Master (45-49)",     code: 'M2',  low: 45,  high: 49,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Master (50-54)",     code: 'M3',  low: 50,  high: 54,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Master (55-59)",     code: 'M4',  low: 55,  high: 59,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Master (60-64)",     code: 'M5',  low: 60,  high: 64,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Master (65-69)",     code: 'M6',  low: 65,  high: 69,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Master (70-74)",     code: 'M7',  low: 70,  high: 74,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Master (75-79)",     code: 'M8',  low: 75,  high: 79,   default: true,  records: true,  weightClasses: uspaWeightClasses},
  {name: "Untested Master (80+)",       code: 'M9',  low: 80,  high: 999,  default: true,  records: true,  weightClasses: uspaWeightClasses},

  {name: "Guest",              code: 'G',                                  default: false, records: false, weightClasses: uspaWeightClasses}
];

export const exportUSPAResults = function (meet: Meet, dataArray: any) {
  let csvObject: Record<
    | "place"
    | "name"
    | "state"
    | "weightClass"
    | "weight"
    | "age"
    | "squat1"
    | "squat2"
    | "squat3"
    | "bench1"
    | "bench2"
    | "bench3"
    | "dead1"
    | "dead2"
    | "dead3"
    | "totalKg"
    | "dotsTotal"
    | "mccTotal",
    string | number | null | undefined
  >[] = [];
  let previousDivisionName = "";
  let previousWeightClassName = "";
  each(dataArray, (lifter, index) => {
    if (!lifter || lifter.row === "header") {
      return;
    }

    if (lifter.row === "title") {
      if (
        previousDivisionName !== lifter.division.name &&
        previousWeightClassName !== lifter.weightClass.name
      ) {
        csvObject.push({
          place: "",
          name: "",
          state: "",
          weightClass: "",
          weight: "",
          age: "",
          squat1: "",
          squat2: "",
          squat3: "",
          bench1: "",
          bench2: "",
          bench3: "",
          dead1: "",
          dead2: "",
          dead3: "",
          totalKg: "",
          dotsTotal: "",
          mccTotal: "",
        });

        previousWeightClassName = lifter.weightClass.name;
      }

      if (previousDivisionName !== lifter.division.name) {
        csvObject.push({
          place: "",
          name: lifter.division.name,
          state: "",
          weightClass: "",
          weight: "",
          age: "",
          squat1: "",
          squat2: "",
          squat3: "",
          bench1: "",
          bench2: "",
          bench3: "",
          dead1: "",
          dead2: "",
          dead3: "",
          totalKg: "",
          dotsTotal: "",
          mccTotal: "",
        });
        previousDivisionName = lifter.division.name;
      }

      csvObject.push({
        place: "",
        name: `${lifter.weightClass.name} ${toLower(meet.units) || "kg"}`,
        state: "",
        weightClass: "",
        weight: "",
        age: "",
        squat1: "",
        squat2: "",
        squat3: "",
        bench1: "",
        bench2: "",
        bench3: "",
        dead1: "",
        dead2: "",
        dead3: "",
        totalKg: "",
        dotsTotal: "",
        mccTotal: "",
      });
    } else {
      const division = lifter.division;
      const weightClass = lifter.weightClass;
      let totalKg: string | number | undefined = getTotal(
        lifter,
        division._id,
        meet
      );
      if (isLbsMeet(meet)) {
        totalKg = toKg(totalKg).toFixed(2);
      }

      csvObject.push({
        place: lifter.place || "DQ",
        name: lifter.name,
        state: lifter.state,
        weightClass: weightClass.name,
        weight: lifter.bodyWeight,
        age: getRealAge(lifter, meet),
        squat1: getAttemptDisplay(lifter, "squat", "1"),
        squat2: getAttemptDisplay(lifter, "squat", "2"),
        squat3: getAttemptDisplay(lifter, "squat", "3"),
        bench1: getAttemptDisplay(lifter, "bench", "1"),
        bench2: getAttemptDisplay(lifter, "bench", "2"),
        bench3: getAttemptDisplay(lifter, "bench", "3"),
        dead1: getAttemptDisplay(lifter, "dead", "1"),
        dead2: getAttemptDisplay(lifter, "dead", "2"),
        dead3: getAttemptDisplay(lifter, "dead", "3"),
        totalKg: totalKg,
        dotsTotal: getDotsPoints(lifter, division._id, meet).toFixed(3),
        mccTotal: getDotsAndAgePoints(lifter, division._id, meet).toFixed(3),
      });
    }
  });

  const header = {
    place: "",
    name: "Name",
    state: "State",
    weightClass: "Class",
    weight: "Weight",
    age: "Age",
    squat1: "SQ1",
    squat2: "SQ2",
    squat3: "SQ3",
    bench1: "BP1",
    bench2: "BP2",
    bench3: "BP3",
    dead1: "DL1",
    dead2: "DL2",
    dead3: "DL3",
    totalKg: "Total Kg",
    dotsTotal: "Dots Total",
    mccTotal: "McC Total",
  };

  csvObject.unshift(header);

  const title = {
    place: "",
    name: meet.date,
    state: meet.name,
    weightClass: "",
    weight: "",
    age: "",
    squat1: "",
    squat2: "",
    squat3: "",
    bench1: "",
    bench2: "",
    bench3: "",
    dead1: "",
    dead2: "",
    dead3: "",
    totalKg: "",
    dotsTotal: "USPA",
    mccTotal: "",
  };

  csvObject.unshift(title);

  return csvObject;
};
